import styled from 'styled-components';

const NavStyled = styled.div`
  position:absolute;
  bottom:${props => 38 - props.params.bottom/props.params.ratio}px;
  left:${props => -props.params.left/props.params.ratio}px;
  width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#FFF;
  opacity:0;
  transition:opacity 1s;
  pointer-events: none;
  &.visible.show {
    opacity:1;
    pointer-events:all;
  }
  .info {
    font-size:26px;
    text-transform:uppercase;
    margin: 0 10px;
  }
  button {
    border:none;
    background:none;
    padding:none;
    padding:0 15px;
    cursor:pointer;
    &.prev {
      opacity:1;
      svg {
        transform:scale(-1);
      }
      &:hover {
        animation: 1s linear 0s infinite prev;
      }
      .xs &:hover {
        animation: none;
      }
    }
    &.next {
      &:hover {
        animation: 1s linear 0s infinite next;
      }
      .xs &:hover {
        animation: none;
      }
    }
  }
  .lang-selector {
    position:absolute;
    width:70px;
    height:70px;
    bottom:-18px;
    left:20px;
    cursor:pointer;
    .back {
      position:absolute;
      top:25%;
      left:25%;
      width:50%;
      height:50%;
      border-radius:50%;
      background-color:#0004;
      filter:blur(20px);
    }
  }
  .langs {
    position:absolute;
    height:70px;
    bottom:-18px;
    left:100px;
    display:flex;
    align-items:center;
    opacity:0;
    pointer-events:none;
    transition:opacity 0.5s;
    &.open {
      opacity:1;
      pointer-events:all;
    }
    .front {
      position:relative;
    }
    .lang {
      cursor:pointer;
      margin:0 10px;
    }
    .back {
      position:absolute;
      top:25%;
      left:-25%;
      width:150%;
      height:50%;
      border-radius:50%;
      background-color:#0000001f;
      filter:blur(20px);
    }
  }
  .xs & {
    bottom:101px;
    .lang-selector, .langs {
      display:none;
    }
    .info {
      font-size:26px;
      text-transform:uppercase;
      color:${props=>props.css.darkGrey};
      width: 88px;
      height: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      padding:0;
      margin:0;
      text-align: center;
    }
    button {
      padding:0;
      svg {
        height:35px;
        width:30px;
      }
    }
  }
  @keyframes prev {
    0% { opacity:1;transform:translate3d(0,0,0); }
    100% { opacity:0;transform:translate3d(-10px,0,0); }
  }
  @keyframes next {
    0% { opacity:1;transform:translate3d(0,0,0); }
    100% { opacity:0;transform:translate3d(10px,0,0); }
  }
`;
export default NavStyled;
