import styled from 'styled-components';

const UiStyled = styled.div`
  position:absolute;
  top:${props => props.params.top}px;
  left:${props => props.params.left}px;
  width:${props => props.params.width}px;
  height:${props => props.params.height}px;
  transform-origin: top left;
  transform:scale(${props => props.ratio});
  display:flex;
  justify-content: flex-start;
  align-items: center;
  pointer-events:none;
  .greymask {
    position:absolute;
    top:${props => -props.params.top/props.params.ratio}px;
    left:${props => -props.params.left/props.params.ratio}px;
    width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
    height:${props => props.params.height + props.params.top/props.params.ratio + props.params.bottom/props.params.ratio}px;
    background-color: #65656533;
    transition: background-color 1s;
    opacity:0;
    &.visible{
       opacity: 1;
    }
    transition: opacity 0.5s;
  }
  .turn-helper {
    pointer-events:none;
    position:absolute;
    top:${props => -props.params.top/props.params.ratio}px;
    left:${props => -props.params.left/props.params.ratio}px;
    width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
    height:${props => props.params.height + props.params.top/props.params.ratio + props.params.bottom/props.params.ratio}px;
    background-color: #65656588;
    transition: opacity 1s;
    opacity:0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    .anim {
      width:60px;
      height:60px;
    }
    .info {
      text-transform:uppercase;
      color:#FFF;
    }
    &.visible{
       opacity: 1;
    }
    transition: opacity 0.5s;
  }
  font-family:'century_gothicregular';
  backface-visibility: hidden;

  &.started {
    background-color: #aaa0;
  }
  .ui-overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0;
    pointer-events:all;
    &.show{
      opacity: 1;
      .frame {
        pointer-events:all;
        &.fullscreen {
          pointer-events:none;
        }
      }
    }
    &.showturn{
      pointer-events:none;
    }
    transition: opacity 0.5s;
  }
  .btn {
    width:300px;
    height:300px;
    pointer-events: all;
  }
  .svg-shadow {
    svg {
      filter: drop-shadow( 0px 0px 2px #FFF);
    }
  }
  .svg-shadow-wide {
    svg {
      filter: drop-shadow( 0px 0px 5px #FFF);
    }
  }
  .svg-shadow-lite {
    svg {
      filter: drop-shadow( 0px 0px 2px #FFF8);
    }
  }
`;
export default UiStyled;
