import { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three'
import { CameraControls, Environment } from '@react-three/drei';
import { SettingsContext } from 'providers/SettingsProvider';
import Data from "Data.js";
import SalleStyled from './SalleStyled'
// import { useControls } from 'leva'
import { getProject, onChange } from '@theatre/core';
import State from './state.json';
import Shoe from './Shoe';
import Masterpiece from './Masterpiece';
import Limited from './Limited';
import Room from './Room';
import RoomXs from './RoomXs';

import { useInView } from 'react-intersection-observer'
THREE.ColorManagement.enabled = true;
THREE.ColorManagement.legacyMode = false
const bigGreyMaterial = new THREE.MeshBasicMaterial({ color: 0x8f999e, side:THREE.BackSide, transparent:false});
const greyMaterial = new THREE.MeshBasicMaterial({ color: 0x8f999e, side:THREE.BackSide, transparent:true, opacity:0 });
const bigCube = new THREE.BoxGeometry(15, 20, 15);
const cube = new THREE.BoxGeometry(1, 1, 3);
const DisableRender = () => useFrame(() => null, 1000)
const project = getProject('My Project',{state:State});
const ipadSheet = project.sheet('My Sheet');
const mobileSheet = project.sheet('mobile');
const defaultCamera={
  distance: 5,
  target: { x: 0, y: 0, z: 0 },
  rotation: {a:0 ,p:(Math.PI / 2)+0.22},
  fov:50,
  roomOpacity:1,
  shoeOpacity:1,
  limitedOpacity:1,
  masterpieceOpacity:1,
  shoe:{
    a:1,
    b:1,
    c:1,
    d:1,
    e:1,
  },
  limited:{
    a:1,
    b:1,
  },
  masterpiece:{
    a:1,
    b:1,
  }
}
const ipadCamera = ipadSheet.object('My Camera', defaultCamera);
const mobileCamera = mobileSheet.object('My Camera', defaultCamera);
let sheet = Data.mode==='retail' ? ipadSheet : ipadSheet;
let cam = Data.mode==='retail' ? ipadCamera : ipadCamera;

const setOpacity=(object,opacity,opacityMap=null)=>{
  if (object && opacity>=0) {
    if(object.material) {
      const part = object.userData.part || null;
      const childOpacity = part ? opacityMap[part] : 1;
      object.material.opacity=opacity*childOpacity;
      object.updateMatrix();
    }
    for(const child of (object.children||[])) {
      setOpacity(child,opacity,opacityMap);
    }
  }
}
const setOpacityRoom=(masks,opacity,layerId,cam)=>{
  if (masks && opacity>=0) {
    if (opacity===1) {
      greyMaterial.opacity=0;
      cam.layers.set(0);
    }
    if (opacity===0) {
      greyMaterial.opacity=1;
      cam.layers.set(layerId);
    }
    if (opacity>0 && opacity<1) {
      greyMaterial.opacity=1-opacity;
      cam.layers.set(0);
    }
  }
}
export default function Salle({dev=false,studio=null}) {
  const { getBlobUrl, loadState, setLoadState, appState, setAppState, refs, transitionId, screenSize, appWidth, appHeight, wpWidth, wpHeight, wpLeft, wpTop } = useContext(SettingsContext);
  const { freeze3D, xsModalOpen, xpStarted, step, discoverStep, poiStep, uiStep, colors } = appState;
  const { assetsReady, fullyLoaded } = loadState;
  const [ cameraReady, setCameraReady ] = useState(false);
  const [ canvasReady, setCanvasReady ] = useState(false);
  const [ projectReady, setProjectReady ] = useState(false);
  const { ref, inView } = useInView();
  const setShowTurnHelper=useCallback((showTurnHelper)=>{
    setAppState(state=>{return{...state,showTurnHelper}});
  },[setAppState]);
  const helperTimer=useRef(0);
  const shoeMaskRef=useRef(null);
  const limitedMaskRef=useRef(null);
  const masterpieceMaskRef=useRef(null);
  const stepRef=useRef(0);
  const cameraControlsRef = useRef(null);
  const canvasRef=useRef(null);
  const cameraFovRef = useRef(Data.fov[screenSize]);
  const [ hasGoneRight, setHasGoneRight ]=useState(false);
  const right=useMemo(()=>uiStep==='discover' && (discoverStep==='main' || poiStep!==null),[discoverStep,uiStep,poiStep]);
  const resetRight=useMemo(()=>uiStep==='main',[uiStep]);
  const resetCam=useCallback(()=>{
    const c=cameraControlsRef.current;
    if (c) {
      c.dollyTo(cam.value.distance,true);
      c.rotateTo(cam.value.rotation.a,cam.value.rotation.p,true);
      c.moveTo(cam.value.target.x,cam.value.target.y,cam.value.target.z,true);
      c.camera.setFocalLength(cam.value.fov*cameraFovRef.current);
    }
  },[]);
  useEffect(()=>{
    if(screenSize==='xs') {
      cam=mobileCamera;
      sheet=mobileSheet;
    } else {
      cam=ipadCamera;
      sheet=ipadSheet;
    }
  },[screenSize])
  useEffect(()=>{
    stepRef.current=step;
  },[step]);
  useEffect(()=>{
    if (shoeMaskRef.current) {
      shoeMaskRef.current.visible= step===1;
    }
    if (limitedMaskRef.current) {
      limitedMaskRef.current.visible=step===2;
    }
    if (masterpieceMaskRef.current) {
      masterpieceMaskRef.current.visible=step===3;
    }
  },[step]);
  const stopShowTurn=useCallback(()=>{
    clearTimeout(helperTimer.current);
    setShowTurnHelper(false);
  },[setShowTurnHelper]);
  useEffect(()=>{
    console.log({uiStep,poiStep});
    if (uiStep==='discover' && poiStep===null) {
      helperTimer.current=setTimeout(function () {
        setShowTurnHelper(true);
      }, 4000);
      return ()=>clearTimeout(helperTimer.current);
    } else {
      stopShowTurn();
    }
  },[uiStep,poiStep,stopShowTurn,setShowTurnHelper]);
  useEffect(()=>{
    cameraFovRef.current=Data.fov[screenSize];
    sheet.sequence.position+=0.00001
  },[screenSize]);
  useEffect(()=>{
    setHasGoneRight(false);
  },[uiStep,setHasGoneRight]);
  useEffect(()=>{
    if (right) setHasGoneRight(true);
  },[right,setHasGoneRight]);
  useEffect(()=>{
    if (studio) {
      studio.initialize();
    }
    project.ready.then(() => {
      setProjectReady(true)
    })
  },[studio]);
  useEffect(()=>{
    if (!dev && projectReady && transitionId) {
      const transition=Data.stepState[transitionId];
      if (transition) {
        const playlist=[];
        if (Array.isArray(transition)) {
          for(const trId of transition) {
            playlist.push(Data.stepState[trId]);
          }
        } else {
          playlist.push(transition);
        }
        const getParams=(transition)=>{
          const {from,to,direction,duration} = transition;
          console.log({from,to,direction,duration});
          const rate=1/duration;
          return {range:[from,to],direction,rate}
        }
        const playTransition=(playlist,i)=>{
          if (i<playlist.length-1) {
            sheet.sequence.play(getParams(playlist[i])).then(()=>playTransition(playlist,i+1));
          } else {
            sheet.sequence.play(getParams(playlist[i]));
          }
        }
        playTransition(playlist,0);
      }
    }
  },[projectReady, dev, transitionId]);
  const turnEnd=useCallback(()=>{
    setAppState(state=>{return{
      ...state, turning:false
    }});
    resetCam();
  },[setAppState,resetCam]);
  const turnStart=useCallback((e)=>{
    if (
      (e && e.button===0)
    ) {
      stopShowTurn();
      setAppState(state=>{return{
        ...state, turning:true
      }});
    }
  },[setAppState,stopShowTurn]);
  useEffect(()=>{
    if(xsModalOpen) stopShowTurn();
  },[xsModalOpen,stopShowTurn]);
  const onRefChange = useCallback(node => {
    if (node !== null) {
      refs.current.camera=node.camera;
      cameraControlsRef.current=node;
      node.mouseButtons.middle=0;
      node.mouseButtons.right=0;
      node.mouseButtons.wheel=0;
      node.mouseButtons.shiftLeft=0;
      node.touches.two=0;
      node.touches.three=0;
      setCameraReady(true);
    }
  }, [setCameraReady,refs]);
  const onCanvasRefChange = useCallback(node => {
    if (node !== null) {
      canvasRef.current=node;
      setCanvasReady(true);
    }
  }, []);
  useEffect(()=>{
    if (canvasReady) {
      console.log('attach events');
      const node=canvasRef.current;
      node.addEventListener('pointerdown',turnStart)
      node.addEventListener('pointerup',turnEnd)
      document.addEventListener('click',turnEnd)
      document.addEventListener('pointerout',turnEnd)
      return ()=>{
        console.log('remove events');
        node.removeEventListener('pointerdown',turnStart)
        node.removeEventListener('pointerup',turnEnd)
        document.removeEventListener('click',turnEnd)
        document.removeEventListener('pointerout',turnEnd)
      }
    }
  },[turnStart,turnEnd,canvasReady]);
  const onRoomRefChange = useCallback(node => {
    if (node !== null) {
      node.layers.enable(0);
      refs.current.room=node
      console.log('room ready');
      setLoadState(state=>{return{...state,roomReady:true}});
    }
  }, [refs,setLoadState]);
  const onShoeRefChange = useCallback((node)=>{
    if (node !== null) {
      refs.current.shoe=node
      console.log('shoe ready');
      setLoadState(state=>{return{...state,shoeReady:true}});
    }
  }, [refs,setLoadState]);
  const onLimitedRefChange = useCallback(node => {
    if (node !== null) {
      node.layers.enable(0);
      node.layers.enable(2);
      refs.current.limited=node
      console.log('limited ready');
      setLoadState(state=>{return{...state,limitedReady:true}});
    }
  }, [refs,setLoadState]);
  const onMasterpieceRefChange = useCallback(node => {
    if (node !== null) {
      node.layers.enable(0);
      node.layers.enable(3);
      refs.current.masterpiece=node
      console.log('masterpiece ready');
      setLoadState(state=>{return{...state,masterpieceReady:true}});
    }
  }, [refs,setLoadState]); // adjust deps
  const onBackgroundRefChange = useCallback(node => {
    if (node !== null) {
      node.layers.enable(1);
      node.layers.enable(2);
      node.layers.enable(3);
    }
  }, []); // adjust deps
  useEffect(()=>{
    if (cameraReady) {
      let oldValues={};
      const unsubscribe=cam.onValuesChange((newValues) => {
        const c=cameraControlsRef.current;
        if (oldValues.distance !== newValues.distance) c.dollyTo(newValues.distance,false);
        if (!oldValues.rotation || (oldValues.rotation.a !== newValues.rotation.a || oldValues.rotation.p !== newValues.rotation.p)) c.rotateTo(newValues.rotation.a,newValues.rotation.p,false);
        if (!oldValues.target || (oldValues.target.x !== newValues.target.x || oldValues.target.y !== newValues.target.y || oldValues.target.z !== newValues.target.z)) c.moveTo(newValues.target.x,newValues.target.y,newValues.target.z,false);
        if (oldValues.fov !== newValues.fov) c.camera.setFocalLength(newValues.fov*cameraFovRef.current);
        if (oldValues.roomOpacity !== newValues.roomOpacity) {
          setOpacityRoom([shoeMaskRef.current,limitedMaskRef.current,masterpieceMaskRef.current],newValues.roomOpacity,stepRef.current,refs.current.camera);
        }
        if (
          oldValues.shoeOpacity !== newValues.shoeOpacity
          || oldValues.shoe.a !== newValues.shoe.a
          || oldValues.shoe.b !== newValues.shoe.b
          || oldValues.shoe.c !== newValues.shoe.c
          || oldValues.shoe.d !== newValues.shoe.d
          || oldValues.shoe.e !== newValues.shoe.e
        ) {
          const s=refs.current.shoe;
          const {a,c,d,e}= newValues.shoe;
          setOpacity(s,newValues.shoeOpacity,{emblem:a,leather:d,sole:c,other:e});
        }
        if (oldValues.limitedOpacity !== newValues.limitedOpacity
          || oldValues.limited.a !== newValues.limited.a
          || oldValues.limited.b !== newValues.limited.b
        ) {
          const l=refs.current.limited;
          const {a,b}= newValues.limited;
          setOpacity(l,newValues.limitedOpacity,{wrapping:b,bottle:a});
        }
        if (oldValues.masterpieceOpacity !== newValues.masterpieceOpacity
          || oldValues.masterpiece.a !== newValues.masterpiece.a
          || oldValues.masterpiece.b !== newValues.masterpiece.b
        ) {
          const m=refs.current.masterpiece;
          const {a,b}= newValues.masterpiece;
          setOpacity(m,newValues.masterpieceOpacity,{wrapping:b,bottle:a});
        }
        oldValues={...newValues,rotation:{...newValues.rotation}, target:{...newValues.target}};
      });
      if (cam.sequence) cam.sequence.position = 0;
      return unsubscribe;
    }
  },[cameraReady,refs]);
  useEffect(()=>{
    onChange(ipadSheet.sequence.pointer.playing, (playing) => {
      setAppState(state=>{return{...state,playing}});
    })
    onChange(mobileSheet.sequence.pointer.playing, (playing) => {
      setAppState(state=>{return{...state,playing}});
    })
  },[setAppState]);
  // const colors = useControls(
  //   'colors',
  //   {
  //     ...Data.colors
  //   },
  //   { collapsed: true }
  // );
  useEffect(()=>{
    setAppState(state=>{return{...state,colors}})
  },[colors,setAppState]);
  return (
    <SalleStyled
    className={screenSize+(Data.mode==='embed' ? ' embed' : '')}
    params={{appWidth,appHeight,wpWidth,wpHeight,wpLeft,wpTop}}
    >
      <div className={'salle'+(step>0 || dev ? ' started' : '')
      +(fullyLoaded ? ' show':'')
      +(screenSize!=='xs' && right ? ' right':'')
      +(screenSize!=='xs' && hasGoneRight ?' hasgoneright':'')
      +(screenSize!=='xs' && resetRight ?' resetright':'')}
      ref={ref}
      aria-hidden>
      {xpStarted && assetsReady && projectReady ? <Canvas ref={onCanvasRefChange} dpr={step===0 ? 0.5 : window.displayPixelRatio} camera={{ position: [0, -0.9, 5], fov: 100, near:0.01, far:20 }}>
          {(!inView || freeze3D) && <DisableRender />}
          <mesh ref={onBackgroundRefChange} position={[0,0,0]} geometry={bigCube} material={bigGreyMaterial}/>
          <ambientLight position={[0,10,2]} intensity={0.3} color='#FFF' layers={[0,1,2,3]}/>
          {screenSize==='xs' ?
            <RoomXs setObject={onRoomRefChange}/>
            :
            <Room setObject={onRoomRefChange}/>
          }
          <Shoe setObject={onShoeRefChange} controls={cameraControlsRef}/>
          <Masterpiece setObject={onMasterpieceRefChange} controls={cameraControlsRef}/>
          <Limited setObject={onLimitedRefChange} controls={cameraControlsRef} />
          <mesh ref={shoeMaskRef} position={[0,-0.43,3.8]} geometry={cube} material={greyMaterial}/>
          <mesh ref={limitedMaskRef} position={[3.2,-0.47,0]} rotation={[0,Math.PI/2,0]} geometry={cube} material={greyMaterial}/>
          <mesh ref={masterpieceMaskRef} position={[-3.2,-0.65,0.5]} rotation={[0,-Math.PI/2,0]} geometry={cube} material={greyMaterial}/>
          <Environment layers={[0,1,2,3]} files={getBlobUrl(Data.nodeParams.potsdamer.src)}/>
          <CameraControls ref={onRefChange} draggingSmoothTime={0.2} smoothTime={0.5} polarAngle={(Math.PI / 2)+0.22} azimuthAngle={0}/>
        </Canvas>
      : ''}
      </div>
    </SalleStyled>
    )
}
