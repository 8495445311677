import styled from 'styled-components';

const AgeStyled = styled.div`
  position:relative;
  width:100%;
  height:100%;
  cursor:pointer;
  text-transform:uppercase;
  text-align:left;
  .datepicker {

      /* default */
      &.default {
          background-color: none;

          .datepicker-header {
              color: #FFF;
          }
          .datepicker-wheel {
              background-color:#FFF;
          }
          .datepicker-caption-item {
              color: #FFF;
          }
          .datepicker-scroll {
              li {
                  color: #FFF;
              }

              li.disabled {
                  color: #FFF;
              }
          }
          .datepicker-navbar-btn {
              color: #FFF;
          }
      }
  }

  .datepicker {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
      font-size: 16px;
      text-align: center;
      box-sizing: content-box;
      -webkit-font-smoothing: antialiased;
      user-select: none;

      .datepicker-header {
          padding: 0 .5em;
          min-height: 2em;
          line-height: 2em;
          font-size: 1.125em;
      }

      .datepicker-navbar {
          padding: 0 .5em .5em .5em;
          overflow: hidden;
      }

      .datepicker-navbar-btn {
          height: 2.5em;
          line-height: 2.5em;
          float: right;
          padding: 0 1em;
          cursor: pointer;
      }

      .datepicker-caption {
          display: flex;
          padding: .5em .25em;
      }

      .datepicker-caption-item {
          flex: 1;
          margin: 0 .25em;
          height: 40px;
          line-height: 40px;
          font-size: 1.2em;
      }

      .datepicker-content {
          display: flex;
          padding: 0;
      }

      .datepicker-col-1 {
          flex: 1;
          margin: 0 .25em;
      }

      .datepicker-viewport {
          height: 200px;
          position: relative;
          overflow: hidden;
          &::after {
              content: '';
              position: absolute;
              z-index: 2;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              pointer-events: none;
          }
      }

      .datepicker-wheel {
          position: absolute;
          height: 40px;
          top: 50%;
          margin-top: -20px;
          width: 100%;
      }

      .datepicker-scroll {
          list-style-type: none;
          padding: 0;
          &>li {
              height: 40px;
              line-height: 40px;
              font-size: 1.375em;
              cursor: pointer;
              color:#fff;
              opacity:0;
              &:nth-child(6) {
                opacity:1;
                color:${props=>props.css.darkGrey};
              }
              &:nth-child(5), &:nth-child(7) {
                opacity:0.6;
              }
              &:nth-child(4), &:nth-child(8) {
                opacity:0.2;
              }
          }
      }
  }
`;
export default AgeStyled;
