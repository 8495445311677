import React, { useContext, useEffect, useRef, useState, useCallback,useMemo } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import Lottie from "lottie-react";
import idleCadreAngle from "assets/IdleCadreAngle.json";
import {ReactComponent as Emblem} from 'assets/MH-EMBLEM.svg';
import {ReactComponent as Logo} from 'assets/LOGO-XO.svg';
import {ReactComponent as Line} from 'assets/line.svg';
import {ReactComponent as Back} from 'assets/back.svg';
import {ReactComponent as LineLeft} from 'assets/LINE_LEFT.svg';
import Data from "Data";
import FrameStyled from './FrameStyled';

function Frame({item,children,delay=1,left=0,top=0,width=400,height=400,backBtnValue=null,backBtnTarget=null}) {
  const { t,experienceLevel, appState, setAppState, dashboardWidth, dashboardHeight, dashboardLeft, dashboardBottom, ratio, screenSize } = useContext(SettingsContext);
  const [reset, setReset] = useState(false);
  const frame = useRef(null);
  const {playing,step,uiStep,poiStep}=appState;
  const fullScreen=useMemo(()=>uiStep==='discover',[uiStep]);
  const btnText=useMemo(()=>poiStep===null ? t('back-to-main-room') : t('back-to-model'),[poiStep,t]);
  const getStyles=useCallback((fs)=>{
    if (fs || screenSize==='xs') {
      return {
        transform:'translate3d(0,0,0) perspective(0) scale(1)',
        width:'calc(100% - 20px)',
        height:'calc(100% - 20px)',
      }
    } else {
      return {
        transform:`translate3d(${left}px,${top}px,0) perspective(0) scale(1)`,
        width:`${width}px`,
        height:`${height}px`,
      }
    }
  },[left,top,width,height,screenSize]);
  const styles=useMemo(()=>getStyles(fullScreen),[getStyles,fullScreen]);
  useEffect(()=>{
    setReset(true);
    const timer=setTimeout(()=>{
      setReset(false);
    },500);
    return ()=>clearTimeout(timer);
  },[item])
  const handleBack=useCallback(()=>{
    if (!playing) {
      if (poiStep===null) {
        setAppState(state=>{
          if (Data.mode==='embed'){
            const d={
              event:'kimJonesSection',
              experienceLevel: experienceLevel[state.step],
              sectionCta:"BackToMainRoom",
            };
            console.log('datalayer push',d);
            if (window.dataLayer) window.dataLayer.push(d);
          }
          return {
            ...state,
            uiStep:'main'
          }
        });
        setTimeout(()=>{
          setAppState(state=>{return{...state,discoverStep:'main',poiStep:null}});
        },1000);
      } else {
        setAppState(state=>{
          if (Data.mode==='embed'){
            const d={
              event:'kimJonesSection',
              experienceLevel: experienceLevel[state.step],
              sectionCta:"BackToModel",
            };
            console.log('datalayer push',d);
            if (window.dataLayer) window.dataLayer.push(d);
          }
          return{...state,poiStep:null}
        });
      }
    }
  },[setAppState,poiStep,playing,experienceLevel]);
  const handleDiscover=useCallback(()=>{
    if (!playing && step>0 && uiStep==='main') {
      setAppState(state=>{
        if (Data.mode==='embed'){
          const d={
            event:'kimJonesCtaClick',
            ctaName:'Discover',
            experienceLevel: experienceLevel[state.step],
          };
          console.log('datalayer push',d);
          if (window.dataLayer) window.dataLayer.push(d);
        }
        return {
          ...state,
          uiStep:'discover',
          previousStep:state.step
        }
      });
    }
  },[setAppState,uiStep,step,playing,experienceLevel]);
  return (
    <FrameStyled angleSize='25px' angleSizeHalf='12.5px' css={Data.css} params={{dashboardWidth, dashboardHeight, dashboardLeft, dashboardBottom, ratio}}>
  <div onClick={handleDiscover} onKeyDown={(e)=>(e.keyCode===32 || e.keyCode===13) && handleDiscover()} tabIndex={step>0 && uiStep==='main' ? 0 : -1} className={'frame'+(fullScreen && screenSize!=='xs' ? ' has-btn':'')+(fullScreen ? ' fullscreen':'')+(reset ? ' reset':'')} style={styles} ref={frame}>
        <div className='angle-top-left svg-shadow' aria-hidden>
          <Lottie animationData={idleCadreAngle}/>
        </div>
        <div className='angle-top-right svg-shadow' aria-hidden>
          <Lottie animationData={idleCadreAngle}/>
        </div>
        <div className='angle-bottom-left svg-shadow' aria-hidden>
          <Lottie animationData={idleCadreAngle}/>
        </div>
        <div className='angle-bottom-right svg-shadow' aria-hidden>
          <Lottie animationData={idleCadreAngle}/>
        </div>
        {screenSize !=='xs' && <div className='logo' aria-hidden>
          <Logo/>
        </div>}
        <div className='emblem-left' aria-hidden>
          <Emblem/>
        </div>
        <div className='emblem-right' aria-hidden>
          <Emblem/>
        </div>
        <div className='line-top-left' aria-hidden>
          <Line/>
        </div>
        <div className='line-top-right' aria-hidden>
          <Line/>
        </div>
        <div className='line-left-top' aria-hidden>
          <LineLeft/>
        </div>
        <div className='line-left-bottom' aria-hidden>
          <LineLeft/>
        </div>
        <div className='line-right-top' aria-hidden>
          <LineLeft/>
        </div>
        <div className='line-right-bottom' aria-hidden>
          <LineLeft/>
        </div>
        <div className='line-bottom-left' aria-hidden>
          <Line/>
        </div>
        <div className='line-bottom-right' aria-hidden>
          <Line/>
        </div>
        {fullScreen && screenSize!=='xs' && <button className="back-btn" onClick={handleBack}>
          {btnText}
        </button>}
        {screenSize==='xs' && <button className={"back-btn"+(uiStep==='discover' && poiStep===null?' show':'')} onClick={handleBack}>
          <Back/>
        </button>}
        <div className='frame-inner'>
          {children}
        </div>
      </div>
    </FrameStyled>
  )
}
export default Frame;
