import React, { useContext,useMemo } from 'react';
import Frame from 'components/Ui/components/Frame';
import DiscoverFrame from 'components/Ui/components/DiscoverFrame';
import Window from 'components/Ui/components/Window';
import WindowXs from 'components/Ui/components/WindowXs';
import Poi from 'components/Ui/components/Poi';
import PoiFocus from 'components/Ui/components/PoiFocus';
import WindowXsModal from 'components/Ui/components/WindowXsModal';
import OverlayStyled from './OverlayStyled';
import { SettingsContext } from 'providers/SettingsProvider';
import Lottie from "lottie-react";
import turnIcon from "assets/360-ICON.json";

function Overlay({item,delay}) {
  const { appState, setAppState, t, screenSize } = useContext(SettingsContext);
  const { poiStep, uiStep, xsModalOpen }=appState;
  const { discoverFrame, discoverWindow, pois }=item;
  const xsModalClose=()=>{
    setAppState(state=>{return{...state,xsModalOpen:false}})
  }
  const showTurn=useMemo(()=>uiStep==='discover' && poiStep===null,[uiStep,poiStep]);
  return (
    <OverlayStyled>
      <Frame
      item={item.id}
      delay={delay}
      top={item.top}
      left={item.left}
      width={item.width}
      height={item.height}
      fullScreen={item.fullScreen}
      backBtnValue={item.backBtnValue}
      backBtnTarget={item.backBtnTarget}
      >
        {discoverFrame && <DiscoverFrame title={t(discoverFrame.title)} subtitle={t(discoverFrame.subtitle)} top={discoverFrame.top || 0}/>}
        {discoverWindow && screenSize!=='xs' && <Window title={t(discoverWindow.title)} subtitle={t(discoverWindow.subtitle)} text={t(discoverWindow.text,'html')}/>}
        {discoverWindow && screenSize==='xs' && <WindowXs title={t(discoverWindow.title)} subtitle={t(discoverWindow.subtitle)} text={t(discoverWindow.text,'html')}/>}
        {pois && pois.map((poi,i)=><React.Fragment key={'poi'+i}>
          <Poi itemName={discoverFrame.title} number={i} title={poi.title} subtitle={poi.subtitle} side={poi.side} left={screenSize==='xs' ? poi.leftXs : poi.left} top={screenSize==='xs' ? poi.topXs : poi.top}/>
          <PoiFocus number={i} title={t(poi.title)} subtitle={t(poi.subtitle)} text={t(poi.text,'html')}/>
        </React.Fragment>)}
        {screenSize!=='xs' && <div className={'turn'+(showTurn ? ' visible':'')} aria-hidden>
          <Lottie animationData={turnIcon}/>
          {t('Turn-the-model')}
        </div>}
      </Frame>
      {discoverWindow && screenSize==='xs' && <WindowXsModal title={t(discoverWindow.title)} subtitle={t(discoverFrame.subtitle)} text={t(discoverWindow.text,'html')} open={xsModalOpen} onClose={xsModalClose}/>}
    </OverlayStyled>
  )
}
export default Overlay;
