import React, { useContext,useCallback,useState  } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import StartStyled from './StartStyled';
import Data from 'Data';

function Start({className}) {
  const { screenSize, setAppState, t, ratio, dashboardWidth, dashboardHeight, dashboardLeft, dashboardBottom, dashboardTop} = useContext(SettingsContext);
  const [ ready,setReady ] = useState(false);
  const handleStart=()=>{
    setAppState(state=>{return{...state,xpStarted:true}});
    if (Data.mode==='embed'){
      const d={
        event:'kimJonesStart',
      };
      console.log('datalayer push',d);
      if (window.dataLayer) window.dataLayer.push(d);
    }
  }
  const width=dashboardWidth;
  const height=dashboardHeight;
  const left=dashboardLeft;
  const bottom=dashboardBottom;
  const top=dashboardTop;
  const onRefChange=useCallback((node) => {
    if (node) {
      node.defaultMuted = true;
      node.muted = true;
      setReady(true);
    }
  }, [setReady]);
  return (
    <StartStyled className={className} css={Data.css} params={{width,height,left,top,bottom,ratio}}>
      <video ref={onRefChange} src={ready ? Data.videoIntro[screenSize] : undefined} playsInline autoPlay loop aria-hidden/>
      <h1>{t('start-title','html')}</h1>
      <div className='btn-container'>
        <button onClick={handleStart}>{t('start-the-experience')}</button>
      </div>
    </StartStyled>
  )
}
export default Start;
