import Queue from 'queue';
import fetchProgress from 'fetch-progress';

const preloadOne=(url, done, that)=>{
	const xhr = new XMLHttpRequest()
	xhr.open('GET', url, true)
	xhr.responseType = 'blob'

	const item = that.getItemByUrl(url)
	fetch(url)
  .then(
    fetchProgress({
      onProgress(progress) {
        item.completion = progress.percentage;
				item.downloaded = progress.transferred;
				item.total = progress.total;
				that.updateProgressBar(item);
      },
      onError(err) {
        console.log('preload failed',item,err);
				item.error = true;
				that.onerror(item);
		    done(item);
      },
    })
  )
  .then(r => {
		const type = r.type
		const responseURL = r.url
		item.fileName = responseURL.substring(responseURL.lastIndexOf('/') + 1)
		item.type = type
		item.status = r.status

		if (item.status === 404) {
			item.blobUrl = item.size = null
			item.error = true
			that.onerror(item);
			done(item);
		} else {
			return r.blob();
		}
	})
	.then(blob=>{
		item.blobUrl = URL.createObjectURL(blob)
		item.size = blob.size;
		item.error = false;
		done(item);
	});
}

export default function customFetch(list) {
	return new Promise((resolve, reject) => {
		this.loaded = list.length
    const q=new Queue({concurrency:1});
		for (let item of list) {
			this.state.push({ url: item })
      q.push((cb)=>{
        preloadOne(item, item => {
					this.onfetched(item)
  				this.loaded--
  				if (this.loaded === 0) {
  					this.oncomplete(this.state)
  					resolve(this.state)
  				}
          cb();
  			},this)
      });
		}
		q.start();
	})
}
