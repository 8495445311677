import React from 'react';
import AgeStyled from './AgeStyled';
import Data from 'Data';
import DatePicker from 'lib/react-mobile-datepicker';

const dateConfig = {
	'year': {
		format: 'YYYY',
		caption: 'Year',
		step: 1,
	},
	'month': {
		format: 'MMMM',
		caption: 'Mon',
		step: 1,
	},
	'date': {
		format: 'DD',
		caption: 'Day',
		step: 1,
	},
};
const min=new Date(1900, 0, 1);
const max=new Date();
function Age({value,onChange}) {
  return (
  <AgeStyled css={Data.css}>
    <DatePicker	dateConfig={dateConfig} max={max} min={min} isOpen isPopup={false} showFooter={false} showHeader={false} value={value} onChange={onChange}/>
  </AgeStyled>
  )
}
export default Age;
