import React, {useState,useContext,useEffect,useRef} from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import {ReactComponent as LoaderIcon} from 'assets/loader.svg';
import {ReactComponent as CercleIcon} from 'assets/cercle.svg';
import { gsap } from "gsap";

const formatter = new Intl.NumberFormat("en", {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function Loader() {
  const { complete, threeReady, progress, setLoadState, loadState, t } = useContext(SettingsContext);
  const [ init,setInit ] = useState(false);
  const [ starting,setStarting ] = useState(false);
  const [ started,setStarted ] = useState(false);
  const [ actualComplete,setActualComplete ] = useState(false);
  const displayCounter=useRef({value:0});
  const counter=useRef(null);
  const gsapInstance=useRef(gsap.quickTo(displayCounter.current, "value", {duration: 1, onUpdate:()=>{
    if (loaderRef.current) {
      const node=loaderRef.current.getElementsByClassName('progress-line')[0];
      node.style.strokeDashoffset=2290*(1-displayCounter.current.value/100);
      counter.current.innerHTML=formatter.format(displayCounter.current.value);
      if (displayCounter.current.value===100) {
        setTimeout(setActualComplete(true),500);
      }
    }
  }}));
  const loaderRef=useRef(null);
  useEffect(()=>setInit(true),[setInit]);
  useEffect(()=>{
    if (init && complete) {
      setLoadState(state=>{return{...state,assetsReady:true}});
    }
  },[complete,init,setLoadState]);
  useEffect(()=>{
    if (init && actualComplete) {
      const timer=setTimeout(()=>setStarting(true),1000);
      return ()=>clearTimeout(timer);
    }
  },[actualComplete,init,setLoadState]);
  useEffect(()=>{
    if (starting && threeReady) {
      setStarted(true)
      const timer=setTimeout(()=>{
        console.log('fullyLoaded');
        setLoadState(state=>{return{...state,fullyLoaded:true}});
      },1500);
      return ()=>clearTimeout(timer);
    }
  },[starting,threeReady,setLoadState]);
  useEffect(()=>{
    if (loaderRef.current) {
      const { roomReady, shoeReady, masterpieceReady, limitedReady } = loadState;
      const done=(d)=>{
        const res=(complete ? 90 : progress)+(roomReady?2.5:d)+(shoeReady?2.5:d)+(masterpieceReady?2.5:d)+(limitedReady?2.5:d);
        return res;
      };
      if (!complete || !roomReady || !shoeReady || !masterpieceReady || !limitedReady) {
        gsapInstance.current(done(0));
      } else {
        gsapInstance.current(100);
      }
    }
  },[complete,progress,loadState]);
  return <div className={'loader-inner'+(started ? ' started' : '')}>
    {starting ? <div className={'starting svg-shadow-wide'}>
      <CercleIcon/>
      <div>{t('starting')}</div>
    </div>:<div className={'bottle svg-shadow-wide'+(init && actualComplete ? ' complete' : '')}>
      <LoaderIcon ref={loaderRef}/>
      <div>{t('initiating-system')}</div>
      <div ref={counter}></div>
    </div>
    }
  </div>;
}
export default Loader;
