import React, { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useThree, useFrame } from '@react-three/fiber'
import { useGLTF } from "@react-three/drei";
import { PointsMaterial, Points, Color } from 'three';
import Utils from "components/Utils";
import Data from "Data";
import Glow from './Glow';
import { SettingsContext } from 'providers/SettingsProvider';

const initialPosition=[2.18,-0.72,0.03];
const initialRotation=[0,0,0];
const [r,g,b] =Utils.hexToRgb(Data.colors.limited).map(o=>o/255);

const LimitedInner=({params,materials,show=false,...props})=>{
  const { getBlobUrl } = useContext(SettingsContext);
  const [ bottle, setBottle ] = useState(null);
  const [ wrapping, setWrapping ] = useState(null);
  const { nodes } = useGLTF(getBlobUrl(params.src));
  useEffect(()=>{
    const {bottleMaterial,wrappingMaterial}=materials;
    const createPoints = (geom,material) => {
      var cloud = new Points(geom, material);
      cloud.layers.enable(0);
      cloud.layers.enable(2);
      return cloud;
    }
    if (nodes && nodes[params.wrapping]) {
      const knots = createPoints(nodes[params.wrapping].geometry,wrappingMaterial.current);
      setWrapping(knots);
    }
    if (nodes && nodes[params.bottle]) {
      const knots = createPoints(nodes[params.bottle].geometry,bottleMaterial.current);
      setBottle(knots);
    }
  },[nodes,materials,params]);
  return <>
    {bottle ? <primitive visible={show} {...props} userData={{ part: 'bottle' }} object={bottle} editableType='points' scale={[0.02,0.02,0.02]} position={[0,-0.2,0]} rotation={[0,2.2,0]}/> : null}
    {wrapping ? <primitive visible={show} {...props} userData={{ part: 'wrapping' }} object={wrapping} editableType='points' scale={[0.02,0.02,0.02]} position={[0,-0.2,0]} rotation={[0,2.2,0]}/> : null}
  </>;
};
const Limited=({setObject,controls,...props})=>{
  const { appState, screenSize } = useContext(SettingsContext);
  const { colors } = appState;
  const [active,setActive] = useState('low');
  const activeRef=useRef(active);
  const c=useRef(null);
  const onRefChange = useCallback(node => {
    if (node !== null) {
      c.current=node;
      setObject(node);
    }
  }, [setObject]);
  const camera=useThree(state=>state.camera);
  const bottleMaterial=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.00125,
    transparent:false,
  }));
  const wrappingMaterial=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.00125,
    transparent:false,
  }));
  const bottleMaterialXs=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.0015,
    transparent:false,
  }));
  const wrappingMaterialXs=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.0015,
    transparent:false,
  }));
  const bottleMaterialXsLow=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.01,
    transparent:false,
  }));
  const wrappingMaterialXsLow=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.01,
    transparent:false,
  }));
  useEffect(()=>{activeRef.current=active},[active])
  useEffect(()=>{
    const [r,g,b] =Utils.hexToRgb(colors.limited).map(o=>o/255);
    wrappingMaterial.current.color=new Color(r,g,b);
    bottleMaterial.current.color=new Color(r,g,b);
    wrappingMaterialXs.current.color=new Color(r,g,b);
    bottleMaterialXs.current.color=new Color(r,g,b);
    wrappingMaterialXsLow.current.color=new Color(r,g,b);
    bottleMaterialXsLow.current.color=new Color(r,g,b);
  },[colors]);
  useFrame((state, delta, xrFrame)=>{
    const time = state.clock.getElapsedTime();
    c.current.position.y=initialPosition[1]+Math.cos( time ) * 0.002;
    c.current.rotation.y=initialRotation[1]+Math.cos( time*0.4 ) * 0.02;
    //wrappingMaterial.current.color.r=f(d);
    //wrappingMaterial.current.color.g=0.4*f(d);
  });
  useEffect(()=>{
    const changeActive=()=>{
      if (c.current) {
        const d=camera.position.distanceTo(c.current.position);
        const a=activeRef.current;
        if (d<2) screenSize==='xs' ? a!=='medium' && setActive('medium') : a!=='high' && setActive('high');
        if (d>=2 && d<3) screenSize==='xs' ? a!=='low' && setActive('low') : a!=='medium' && setActive('medium');
        if (d>=3) a!=='low' && setActive('low');
      }
    };
    if (controls.current) {
      const ctl=controls.current;
      ctl.addEventListener('update',changeActive);
      return ()=>ctl.removeEventListener('update',changeActive);
    }
  },[camera,controls,setActive,screenSize]);
  const materials={bottleMaterial,wrappingMaterial};
  const materialsXs={bottleMaterial:bottleMaterialXs,wrappingMaterial:wrappingMaterialXs};
  const materialsXsLow={bottleMaterial:bottleMaterialXsLow,wrappingMaterial:wrappingMaterialXsLow};
  const glowItems=[
    {position:[0,-0.1,0],scale:1,color:colors.limitedGlow},
    {position:[0,0.05,0],scale:0.5,color:colors.limitedGlow},
    {position:[0,0.2,0],scale:0.3,color:colors.limitedGlow},
  ];
  return <group ref={onRefChange} position={initialPosition} rotation={initialRotation}>
    <LimitedInner show={active==='high'} params={Data.nodeParams.limitedHigh} materials={screenSize==='xs' ? materialsXs : materials}/>
    <LimitedInner show={active==='medium'} params={Data.nodeParams.limitedMedium} materials={screenSize==='xs' ? materialsXs : materials}/>
    <LimitedInner show={active==='low'} params={Data.nodeParams.limitedLow} materials={screenSize==='xs' ? materialsXsLow : materials}/>
    <Glow items={glowItems} layers={[0,2]}/>
  </group>;
};

export default Limited;
