const scriptUrl = new URL(document.currentScript.src);
const mainHost = scriptUrl.origin;
const host = document.querySelector('xo-kim-jones');
const mode = host.getAttribute('mode')==='retail' ? 'retail' : 'embed';
const langAsked=Boolean(host.getAttribute('lang'));
const userLang = ['zh-CN','zh-CHT'].indexOf(host.getAttribute('lang'))!==-1 ? host.getAttribute('lang') : 'en-US';

const Data={
  mode,
  mainHost,
  retail:{
    xs: {
      nominalWidth:320,
      nominalHeight:568,
    },
    sm: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    md: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    lg: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    xl: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    xxl: {
      nominalWidth:1194,
      nominalHeight:834,
    },
  },
  embed: {
    xs: {
      nominalWidth:320,
      nominalHeight:568,
    },
    sm: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    md: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    lg: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    xl: {
      nominalWidth:1194,
      nominalHeight:834,
    },
    xxl: {
      nominalWidth:1194,
      nominalHeight:834,
    },
  },
  idleTimeout:60000,
  css:{
    darkGrey:'#555555',
    backgroundGrey:'#9faaaf',
  },
  defaultLang:'en-US',
  langAsked,
  userLang,
  langs:[
    {code:'en-US',label:'dispatch_langue_retail_en-US'},
    {code:'zh-CN',label:'dispatch_langue_retail_zh-CN'},
    {code:'zh-CHT',label:'dispatch_langue_retail_zh-CHT'},
  ],
  stepState:{
    '0-main-none/1-main-none':{
      from:0,
      to:1,
      duration:5,
      itemFrom:'home',
      itemTo:'shoe',
    },
    '1-main-none/0-main-none':{
      from:0,
      to:1,
      direction:'reverse',
      duration:5,
      itemFrom:'shoe',
      itemTo:'home',
    },
    '1-main-none/1-discover-none':{
      from:5,
      to:6,
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-none/1-main-none':{
      from:5,
      to:6,
      direction:'reverse',
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-none/0-main-none':[
      '1-discover-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '1-discover-none/1-discover-0':{
      from:6,
      to:7,
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-0/1-discover-none':{
      from:6,
      to:7,
      direction:'reverse',
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-0/0-main-none':[
      '1-discover-0/1-discover-none',
      '1-discover-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '1-discover-none/1-discover-1':{
      from:8,
      to:9,
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-1/1-discover-none':{
      from:8,
      to:9,
      direction:'reverse',
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-1/0-main-none':[
      '1-discover-1/1-discover-none',
      '1-discover-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '1-discover-none/1-discover-2':{
      from:10,
      to:11,
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-2/1-discover-none':{
      from:10,
      to:11,
      direction:'reverse',
      duration:1,
      itemFrom:'shoe',
      itemTo:'shoe',
    },
    '1-discover-2/0-main-none':[
      '1-discover-2/1-discover-none',
      '1-discover-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '1-main-none/2-main-none':{
      from:1,
      to:2,
      duration:2,
      itemFrom:'shoe',
      itemTo:'limited',
    },
    '2-main-none/1-main-none':{
      from:1,
      to:2,
      direction:'reverse',
      duration:2,
      itemFrom:'limited',
      itemTo:'shoe',
    },
    '2-main-none/0-main-none':[
      '2-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '2-main-none/2-discover-none':{
      from:12,
      to:13,
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-none/2-main-none':{
      from:12,
      to:13,
      direction:'reverse',
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-none/0-main-none':[
      '2-discover-none/2-main-none',
      '2-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '2-discover-none/2-discover-0':{
      from:13,
      to:14,
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-0/2-discover-none':{
      from:13,
      to:14,
      direction:'reverse',
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-0/0-main-none':[
      '2-discover-0/2-discover-none',
      '2-discover-none/2-main-none',
      '2-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '2-discover-none/2-discover-1':{
      from:15,
      to:16,
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-1/2-discover-none':{
      from:15,
      to:16,
      direction:'reverse',
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-1/0-main-none':[
      '2-discover-1/2-discover-none',
      '2-discover-none/2-main-none',
      '2-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '2-discover-none/2-discover-2':{
      from:17,
      to:18,
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-2/2-discover-none':{
      from:17,
      to:18,
      direction:'reverse',
      duration:1,
      itemFrom:'limited',
      itemTo:'limited',
    },
    '2-discover-2/0-main-none':[
      '2-discover-2/2-discover-none',
      '2-discover-none/2-main-none',
      '2-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '1-main-none/3-main-none':{
      from:3,
      to:4,
      direction:'reverse',
      duration:2,
      itemFrom:'shoe',
      itemTo:'masterpiece',
    },
    '3-main-none/1-main-none':{
      from:3,
      to:4,
      duration:2,
      itemFrom:'masterpiece',
      itemTo:'shoe',
    },
    '3-main-none/0-main-none':[
      '3-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '2-main-none/3-main-none':{
      from:2,
      to:3,
      duration:4,
      itemFrom:'limited',
      itemTo:'masterpiece',
    },
    '3-main-none/2-main-none':{
      from:2,
      to:3,
      direction:'reverse',
      duration:4,
      itemFrom:'masterpiece',
      itemTo:'limited',
    },
    '3-main-none/3-discover-none':{
      from:19,
      to:20,
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-none/3-main-none':{
      from:19,
      to:20,
      direction:'reverse',
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-none/0-main-none':[
      '3-discover-none/3-main-none',
      '3-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '3-discover-none/3-discover-0':{
      from:20,
      to:21,
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-0/3-discover-none':{
      from:20,
      to:21,
      direction:'reverse',
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-0/0-main-none':[
      '3-discover-0/3-discover-none',
      '3-discover-none/3-main-none',
      '3-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '3-discover-none/3-discover-1':{
      from:22,
      to:23,
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-1/3-discover-none':{
      from:22,
      to:23,
      direction:'reverse',
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-1/0-main-none':[
      '3-discover-1/3-discover-none',
      '3-discover-none/3-main-none',
      '3-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
    '3-discover-none/3-discover-2':{
      from:24,
      to:25,
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-2/3-discover-none':{
      from:24,
      to:25,
      direction:'reverse',
      duration:1,
      itemFrom:'masterpiece',
      itemTo:'masterpiece',
    },
    '3-discover-2/0-main-none':[
      '3-discover-2/3-discover-none',
      '3-discover-none/3-main-none',
      '3-main-none/1-main-none',
      '1-main-none/0-main-none',
    ],
  },
  items:{
    home:{
      main: {},
    },
    shoe:{
      top:-120,
      left:5,
      width:500,
      height:500,
      discoverFrame:{
        title:'sneaker-xo',
        subtitle:'by-kim-jones',
        top:20,
      },
      discoverWindow:{
        title:'sneaker-xo',
        subtitle:'by-kim-jones',
        text:'sneaker-description',
        hidden:true,
      },
      pois:[
        {title:'sneaker-item-emblem',subtitle:'sneaker-item-emblem-subtitle',top:-250,left:80,topXs:-250,leftXs:80,side:'right-top',text:'sneaker-item-emblem-description'},
        {title:'sneaker-item-leather',subtitle:'sneaker-item-leather-subtitle',top:-20,left:-240,topXs:-20,leftXs:-140,side:'left-top',text:'sneaker-item-leather-description'},
        {title:'sneaker-item-technical-sole',subtitle:'sneaker-item-technical-sole-subtitle',top:150,left:70,topXs:150,leftXs:70,side:'right-bottom',text:'sneaker-item-technical-sole-description'},
      ]
    },
    limited:{
      top:-100,
      left:20,
      width:420,
      height:630,
      discoverFrame:{
        title:'xo-limited-edition',
        subtitle:'by-kim-jones',
        top:-40,
      },
      discoverWindow:{
        title:'xo-limited-edition',
        subtitle:'by-kim-jones',
        text:'xo-limited-description',
      },
      pois:[
        {title:'xo-limited-edition-item-open-shoulder',subtitle:'xo-limited-edition-item-open-shoulder-subtitle',top:-200,left:100,topXs:-200,leftXs:100,side:'right-top',text:'xo-limited-edition-item-open-shoulder-description'},
        {title:'xo-limited-edition-item-wrapping',subtitle:'xo-limited-edition-item-wrapping-subtitle',top:-100,left:-160,topXs:-100,leftXs:-140,side:'left-top',text:'xo-limited-edition-item-wrapping-description'},
        {title:'xo-limited-edition-item-aluminium-structure',subtitle:'xo-limited-edition-item-aluminium-structure-subtitle',top:50,left:-230,topXs:50,leftXs:-120,side:'left-bottom',text:'xo-limited-edition-item-aluminium-structure-description'},
      ]
    },
    masterpiece:{
      top:-70,
      left:35,
      width:450,
      height:640,
      discoverFrame:{
        title:'xo-masterpiece',
        subtitle:'by-kim-jones',
        top:-100,
      },
      discoverWindow:{
        title:'xo-masterpiece',
        subtitle:'by-kim-jones',
        text:'xo-masterpiece-description',
      },
      pois:[
        {title:'xo-masterpiece-item-folding-logo-detail',subtitle:'xo-masterpiece-item-folding-logo-detail-subtitle',top:30,left:130,topXs:200,leftXs:130,side:'right-top',text:'xo-masterpiece-item-folding-logo-detail-description'},
        {title:'xo-masterpiece-item-wrapping',subtitle:'xo-masterpiece-item-wrapping-subtitle',top:-180,left:-130,topXs:-180,leftXs:-130,side:'left-top',text:'xo-masterpiece-item-wrapping-description'},
        {title:'xo-masterpiece-item-exclusive-pieces',subtitle:'xo-masterpiece-item-exclusive-pieces-subtitle',top:0,left:-130,topXs:100,leftXs:-130,side:'left-top',text:'xo-masterpiece-item-exclusive-pieces-description'},
      ]
    }
  },
  fov:{
    xs:0.65,
    sm:0.65,
    md:0.65,
    lg:0.65,
    xl:0.65,
    xxl:0.65,
  },
  nodeParams:{
    shoeHigh:{src:mainHost+"/app-files/Sneaker_Hennessy_separated.glb",leather:'Leather',sole:'Sole',emblem1:'Emblem_1',emblem2:'Emblem_2',laces:'Laces'},
    shoeMedium:{xs:true,src:mainHost+"/app-files/Sneaker_Hennessy_Separated_MID.glb",leather:'Leather_MID',sole:'Sole_MID',emblem1:'Emblem_MID_1',emblem2:'Emblem_MID_2',laces:'Laces_MID'},
    shoeLow:{xs:true,src:mainHost+"/app-files/Sneaker_Hennessy_Separated_LOW.glb",leather:'Leather_LOW',sole:'Sole_LOW',emblem1:'Emblem_LOW_1',emblem2:'Emblem_LOW_2',laces:'Laces_LOW'},
    limitedHigh:{src:mainHost+"/app-files/Limited_Edition_HIGH02.glb",wrapping:'Limited_Edition_HIGH_1',bottle:'Limited_Edition_HIGH_2'},
    limitedMedium:{xs:true,src:mainHost+"/app-files/Limited_Edition_MID02.glb",wrapping:'Limited_Edition_MID_1',bottle:'Limited_Edition_MID_2'},
    limitedLow:{xs:true,src:mainHost+"/app-files/Limited_Edition_LOW02.glb",wrapping:'Limited_Edition_LOW_1',bottle:'Limited_Edition_LOW_2'},
    masterpieceHigh:{src:mainHost+"/app-files/Masterpiece_HIGH_2.glb",wrapping:'MasterPiece_HIGH',logo:'Logo_HIGH',bottle:'Bottle_HIGH'},
    masterpieceMedium:{xs:true,src:mainHost+"/app-files/Masterpiece_MID_2.glb",wrapping:'MasterPiece_MID',logo:'Logo_MID',bottle:'Bottle_MID'},
    masterpieceLow:{xs:true,src:mainHost+"/app-files/Masterpiece_LOW_2.glb",wrapping:'MasterPiece_LOW',logo:'Logo_LOW',bottle:'Bottle_LOW'},
    enviro:{src:mainHost+"/app-files/Environment_Hennessy08.glb"},
    enviroXs:{xsOnly:true,src:mainHost+"/app-files/Environment_Hennessy_Opti03_2.glb"},
    potsdamer:{xs:true,src:mainHost+"/app-files/potsdamer_platz_1k.hdr"},
  },
  colors:{
    shoe:"#e5af45",
    shoeGlow:"#ff8600",
    limited:"#e5af45",
    limitedGlow:"#ff8600",
    masterpiece:"#e5af45",
    masterpieceGlow:"#ff8600",
  },
  videoIntro:{
    xs:mainHost+"/app-files/xo_portrait.mp4",
    sm:mainHost+"/app-files/xo_landscape.mp4",
    md:mainHost+"/app-files/xo_landscape.mp4",
    lg:mainHost+"/app-files/xo_landscape.mp4",
    xl:mainHost+"/app-files/xo_landscape.mp4",
    xxl:mainHost+"/app-files/xo_landscape.mp4",
  },
  headerHeight:{
    xs:80,
    sm:100,
    md:100,
    lg:100,
    xl:100,
    xxl:100,
  }
}
export default Data;
