import React, { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useThree, useFrame } from '@react-three/fiber'
import { useGLTF } from "@react-three/drei";
import { PointsMaterial, Points, Color } from 'three';
import Utils from "components/Utils";
import Data from "Data";
import { SettingsContext } from 'providers/SettingsProvider';
import Glow from './Glow';

const initialPosition=[0,-0.74,2.6];
const initialRotation=[0,0,0];
const shoePosition=[0.08,-0.16,-0.09];
const shoeRotation=[-Math.PI/2+0.4,-0.2,Math.PI-0.6];
const [r,g,b] =Utils.hexToRgb(Data.colors.shoe).map(o=>o/255);

const ShoeInner=({params,materials,show=false,...props})=>{
  const { getBlobUrl } = useContext(SettingsContext);
  const [ emblem, setEmblem ] = useState(null);
  const [ emblem2, setEmblem2] = useState(null);
  const [ sole, setSole ] = useState(null);
  const [ leather, setLeather ] = useState(null);
  const [ other, setOther ] = useState(null);
  const { nodes } = useGLTF(getBlobUrl(params.src));
  useEffect(()=>{
    if (nodes) {
      const {materialLeather,materialSole,materialEmblem,materialOther}=materials;
      const createPoints = (geom,material) => {
        var cloud = new Points(geom, material);
        cloud.layers.enable(0);
        cloud.layers.enable(1);
        return cloud;
      }
      if (nodes && nodes[params.leather]) {
        const knots = createPoints(nodes[params.leather].geometry,materialLeather.current);
        setLeather(knots);
      }
      if (nodes && nodes[params.sole]) {
        const knots = createPoints(nodes[params.sole].geometry,materialSole.current);
        setSole(knots);
      }
      if (nodes && nodes[params.emblem1]) {
        const knots = createPoints(nodes[params.emblem1].geometry,materialEmblem.current);
        setEmblem(knots);
      }
      if (nodes && nodes[params.emblem2]) {
        const knots = createPoints(nodes[params.emblem2].geometry,materialEmblem.current);
        setEmblem2(knots);
      }
      if (nodes && nodes[params.laces]) {
        const knots = createPoints(nodes[params.laces].geometry,materialOther.current);
        setOther(knots);
      }
    }
  },[nodes,materials,params]);

  // You don't need to check for the presence of the result, when we're here
  // the result is guaranteed to be present since useLoader suspends the component
  return <>
      {emblem ? <primitive visible={show} {...props} userData={{ part: 'emblem' }} object={emblem} scale={[0.02,0.02,0.02]} position={shoePosition} rotation={shoeRotation}/> : null}
      {emblem2 ? <primitive visible={show} {...props} userData={{ part: 'emblem' }} object={emblem2} scale={[0.02,0.02,0.02]} position={shoePosition} rotation={shoeRotation}/> : null}
      {sole ? <primitive visible={show} {...props} userData={{ part: 'sole' }} object={sole} scale={[0.02,0.02,0.02]} position={shoePosition} rotation={shoeRotation}/> : null}
      {leather ? <primitive visible={show} {...props} userData={{ part: 'leather' }} object={leather} scale={[0.02,0.02,0.02]} position={shoePosition} rotation={shoeRotation}/> : null}
      {other ? <primitive visible={show} {...props} userData={{ part: 'other' }} object={other} scale={[0.02,0.02,0.02]} position={shoePosition} rotation={shoeRotation}/> : null}
    </>;
};

const Shoe=({setObject,controls,...props})=>{
  const { appState, screenSize } = useContext(SettingsContext);
  const { step,colors } = appState;
  const c=useRef(null);
  const [active,setActive] = useState(screenSize==='xs' ? 'medium' : 'high');
  const activeRef=useRef(active);
  const unlockHigh=useRef(false);
  useEffect(()=>{activeRef.current=active},[active])
  useEffect(()=>setActive(screenSize==='xs' ? 'medium' : 'high'),[setActive,screenSize]);
  useEffect(()=>{
    if (step>1 && !unlockHigh.current) unlockHigh.current=true;
    if (step===0) unlockHigh.current=false;
  },[step]);
  const camera=useThree(state=>state.camera);
  const materialOther=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.00125,
    transparent:true,
  }));
  const materialSole=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.00125,
    transparent:true,
  }));
  const materialLeather=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.00125,
    transparent:true,
  }));
  const materialEmblem=useRef(new PointsMaterial({
    color: new Color(r,g,b),
    size: 0.00125,
    transparent:true,
  }));
  const onRefChange = useCallback(node => {
    if (node !== null) {
      c.current=node;
      setObject(node);
    }
  }, [setObject]);
  useEffect(()=>{
    const [r,g,b] =Utils.hexToRgb(colors.shoe).map(o=>o/255);
    materialOther.current.color=new Color(r,g,b);
    materialSole.current.color=new Color(r,g,b);
    materialLeather.current.color=new Color(r,g,b);
    materialEmblem.current.color=new Color(r,g,b);
  },[colors]);
  useFrame((state, delta, xrFrame)=>{
    const time = state.clock.getElapsedTime();
    c.current.position.y=initialPosition[1]+Math.cos( time ) * 0.002;
    c.current.rotation.y=initialRotation[1]+Math.cos( time*0.4 ) * 0.02;
    // materialOther.current.color.r=f(d);
    // materialEmblem.current.color.r=f(d);
    // materialSole.current.color.r=f(d);
    // materialLeather.current.color.r=f(d);
  });
  useEffect(()=>{
    const changeActive=()=>{
      if (c.current && unlockHigh.current) {
        const d=camera.position.distanceTo(c.current.position);
        const a=activeRef.current;
        if (d<2) screenSize==='xs' ? a!=='medium' && setActive('medium') : a!=='high' && setActive('high');
        if (d>=2 && d<3) screenSize==='xs' ? a!=='low' && setActive('low') : a!=='medium' && setActive('medium');
        if (d>=3) a!=='low' && setActive('low');
      }
    };
    if (controls.current) {
      const ctl=controls.current;
      ctl.addEventListener('update',changeActive);
      return ()=>ctl.removeEventListener('update',changeActive);
    }
  },[camera,controls,setActive,screenSize]);
  const materials={materialLeather,materialSole,materialEmblem,materialOther};
  const glowItems=[
    {position:[-0.05,-0.07,0.12],scale:0.5,color:colors.shoeGlow},
    {position:[0.02,0,0.05],scale:0.5,color:colors.shoeGlow},
    {position:[0.08,0.06,-0.05],scale:0.5,color:colors.shoeGlow},
  ];
  return <group ref={onRefChange} position={initialPosition} rotation={initialRotation}>
    <ShoeInner show={active==='high'} params={Data.nodeParams.shoeHigh} materials={materials}/>
    <ShoeInner show={active==='medium'} params={Data.nodeParams.shoeMedium} materials={materials}/>
    <ShoeInner show={active==='low'} params={Data.nodeParams.shoeLow} materials={materials}/>
    <Glow items={glowItems} layers={[0,1]}/>
  </group>;
};
export default Shoe;
