import styled from 'styled-components';

const DiscoverFrameStyled = styled.div`
  position:absolute;
  left:calc(100% - 25px);
  top:100px;
  width:320px;
  padding-left:30px;
  text-align:right;
  opacity:1;
  transition: opacity 0.5s;
  &.hidden {
    opacity:0;
    pointer-events: none;
  }
  .line {
    position:absolute;
    top:0;
    right:0;
    .anim {
      position:absolute;
      width:50px;
      top:4px;
      left:-4px;
    }
  }
  .title {
    color:${props => props.css.darkGrey};
    font-size:36px;
    font-family:'hennessy_sansregular';
    font-weight:400;
    margin:0;
    text-transform:uppercase;
    margin-top:15px;
  }
  .subtitle {
    color:${props => props.css.darkGrey};
    font-size:26px;
    color:#FFF;
    text-transform:uppercase;
  }
  .cta {
    display:inline-block;
    background-color:#0000;
    font-family: 'century_gothicregular';
    border:none;
    padding:0;
    margin:0;
    position:relative;
    width:150px;
    height:40px;
    top:10px;
    left:-10px;
    line-height: 40px;
    text-align: center;
    color:${props => props.css.darkGrey};
    &>div {
      position:absolute;
      top:-90px;
      right:-36px;
      width:220px;
      height:220px;
      pointer-events: none;
    }
    &>div.cta-text {
      position:absolute;
      top:0;
      right:0;
      width:100%;
      height:100%;
      cursor:pointer;
      pointer-events: all;
      text-transform: uppercase;
    }
  }
  .xs & {
    left:0;
    top:0;
    width:100%;
    text-align:center;
    padding-left:0;
    .line {
      top:${props => 150 + props.params.dashboardTop/props.params.ratio}px;
      left:110px;
      .anim {
        position:absolute;
        width:50px;
        top:0;
        left:0;
        cursor:pointer;
      }
    }
    .title {
      font-size:20px;
      margin-top:10px;
      padding: 0 42px;
    }
    .subtitle {
      font-size:16px;
      color:${props=>props.css.darkGrey};
      text-transform:uppercase;
    }
    .cta {
      position:absolute;
      top:${props => 162 + props.params.dashboardTop/props.params.ratio}px;
      left:50px;
      width:100px;
      &>div {
        display:none;
      }
      &>div.cta-text {
        display:block;
        top:0;
        right:0;
        width:100%;
        height:100%;
        font-size:12px;
        line-height:12px;
        text-align:left;
        padding-right:30px;
      }
    }
  }
`;
export default DiscoverFrameStyled;
