import styled from 'styled-components';

const FrameStyled = styled.div`
  position:absolute;
  top:${props => -Math.max(props.params.dashboardBottom/props.params.ratio,0)}px;
  left:${props => -Math.max(props.params.dashboardLeft/props.params.ratio,0)}px;
  width:${props => props.params.dashboardWidth + 2*props.params.dashboardLeft/props.params.ratio}px;
  height:${props => props.params.dashboardHeight + 2*props.params.dashboardBottom/props.params.ratio}px;
  display:flex;
  justify-content: center;
  align-items: center;
  .xs & {
    top:${props => -Math.max(props.params.dashboardBottom/props.params.ratio,0) - 10}px;
    left:${props => -Math.max(props.params.dashboardLeft/props.params.ratio,0) - 21}px;
    width:${props => props.params.dashboardWidth + 2*props.params.dashboardLeft/props.params.ratio + 39}px;
    height:${props => props.params.dashboardHeight + 2*props.params.dashboardBottom/props.params.ratio - 10}px;
  }
  .frame {
    position:relative;
    width:600px;
    height:600px;
    opacity:0;
    pointer-events:none;
    transition:transform 1s, width 1s, height 1s, opacity 1s;
    &.reset {
      transition:none;
    }
    &.show {
      opacity:1;
    }
    .logo {
      position:absolute;
      width:90.65px;
      height:36.36px;
      left:calc(50% - 45.325px);
      top:calc(25px - 18.18px);
    }
    .emblem-left {
      position:absolute;
      width:27.71px;
      height:27.86px;
      left:calc(25px - 13.855px);
      top:calc(50% - 13.93px);
    }
    .emblem-right {
      position:absolute;
      width:27.71px;
      height:27.86px;
      right:calc(25px - 13.855px);
      top:calc(50% - 13.93px);
    }
    .angle-top-left {
      position:absolute;
      width:${props => props.angleSize};
      height:${props => props.angleSize};
      top:calc(25px - ${props => props.angleSizeHalf});
      left:calc(25px - ${props => props.angleSizeHalf});
    }
    .angle-top-right {
      position:absolute;
      width:${props => props.angleSize};
      height:${props => props.angleSize};
      top:calc(25px - ${props => props.angleSizeHalf});
      right:calc(25px - ${props => props.angleSizeHalf});
    }
    .angle-bottom-left {
      position:absolute;
      width:${props => props.angleSize};
      height:${props => props.angleSize};
      bottom:calc(25px - ${props => props.angleSizeHalf});
      left:calc(25px - ${props => props.angleSizeHalf});
    }
    .angle-bottom-right {
      position:absolute;
      width:${props => props.angleSize};
      height:${props => props.angleSize};
      bottom:calc(25px - ${props => props.angleSizeHalf});
      right:calc(25px - ${props => props.angleSizeHalf});
    }
    .line-top-left {
      position:absolute;
      width:calc(50% - 115px);
      height:16px;
      overflow:hidden;
      top:20px;
      right:calc(50% + 65px);
      transform:scaleX(-1);
    }
    .line-top-right {
      position:absolute;
      width:calc(50% - 115px);
      height:16px;
      overflow:hidden;
      top:20px;
      left:calc(50% + 65px);
    }
    .line-left-top {
      position:absolute;
      height:calc(50% - 75px);
      width:16px;
      bottom:calc(50% + 25px);
      left:17px;
      overflow:hidden;
      transform:scaleY(-1);
    }
    .line-left-bottom {
      position:absolute;
      height:calc(50% - 75px);
      width:16px;
      top:calc(50% + 25px);
      right:17px;
      overflow:hidden;
    }
    .line-right-top {
      position:absolute;
      height:calc(50% - 75px);
      width:16px;
      bottom:calc(50% + 25px);
      right:17px;
      overflow:hidden;
      transform:scaleY(-1);
    }
    .line-right-bottom {
      position:absolute;
      height:calc(50% - 75px);
      width:16px;
      top:calc(50% + 25px);
      left:17px;
      overflow:hidden;
    }
    .line-top-right {
      position:absolute;
      width:calc(50% - 115px);
      height:16px;
      overflow:hidden;
      top:20px;
      left:calc(50% + 65px);
    }
    .line-bottom-left {
      position:absolute;
      width:calc(50% - 63.5px);
      height:16px;
      overflow:hidden;
      bottom:20px;
      right:calc(50% + 13.5px);
      transform:scale(-1);
    }
    .line-bottom-right {
      position:absolute;
      width:calc(50% - 63.5px);
      height:16px;
      overflow:hidden;
      bottom:20px;
      left:calc(50% + 13.5px);
      transform:scaleY(-1);
    }
    .back-btn {
      position:absolute;
      width:212px;
      padding:0;
      text-align:center;
      line-height: 2.5em;
      font-size:16px;
      font-family: 'century_gothicregular';
      bottom:calc(25px - 1em);
      left:calc(50% - 106px);
      border:0;
      background-color: #FFF;
      color:${props=>props.css.darkGrey};
      cursor:pointer;
      pointer-events:all;
      text-transform:uppercase;
    }
    &.has-btn {
      .line-bottom-left {
        width:calc(50% - 172px);
        right:calc(50% + 122px);
      }
      .line-bottom-right {
        width:calc(50% - 172px);
        left:calc(50% + 122px);
      }
    }
    opacity:1;
    &.fade-out {
      opacity:0;
      .discoverframe {
        opacity:0;
      }
    }
    .frame-inner {
      max-width:${props => props.params.dashboardWidth}px;
      margin:0 auto;
      height:100%;
      position:relative;
      pointer-events:none;
    }
  }
  .xs & .frame {
    .line-top-left {
      position:absolute;
      width:calc(50% - 63.5px);
      right:calc(50% + 13.5px);
    }
    .back-btn {
      position:absolute;
      width:35px;
      height:35px;
      bottom:auto;
      top:51px;
      left:45px;
      background-color: #FFF0;
      cursor:pointer;
      pointer-events:none;
      opacity: 0;
      transition: opacity 1s;
      &.show {
        opacity: 1;
        pointer-events:all;
      }
      svg {
        width:100%;
        height:100%;
      }
    }
    .line-top-right {
      position:absolute;
      width:calc(50% - 63.5px);
      left:calc(50% + 13.5px);
    }
    &>div, &>button {
      opacity:0;
      pointer-events:none;
      transition:opacity 1s;
    }
    .frame-inner {
      opacity:1;
    }
    &.fullscreen>div {
      opacity:1;
    }
  }
`;
export default FrameStyled;
