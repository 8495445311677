import React from 'react';
import ReactDOM from 'react-dom/client';
import { StyleSheetManager } from 'styled-components';
import SettingsProvider from "providers/SettingsProvider";
import App from './App';
import Data from "Data";

const fontsStyle = document.createElement('style');
fontsStyle.appendChild(document.createTextNode(`
@font-face {
  font-family: 'century_gothicregular';
  src: url('${Data.mainHost}/app-files/century_gothic-webfont.woff2') format('woff2'),
       url('${Data.mainHost}/app-files/century_gothic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'hennessy_sansregular';
  src: url('${Data.mainHost}/app-files/hennessy-hennessysans-2015-otf-webfont.woff2') format('woff2'),
       url('${Data.mainHost}/app-files/hennessy-hennessysans-2015-otf-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
xo-kim-jones {
  display:block;
  margin:0;
  padding:0;
}
`));
document.head.appendChild(fontsStyle);
const host = document.querySelector('xo-kim-jones');
const mode = host.getAttribute('mode')==='retail' ? 'retail' : 'embed';

if (mode==='retail') {
  if ('serviceWorker' in navigator) {
    console.log("serviceWorker found")
      navigator.serviceWorker
      .register('/service-worker.js', { scope: '/' })
      .then(function(registration) {
        console.log("Service Worker Registered");
      })
      .catch(function(err) {
        console.log("Service Worker Failed to Register", err);
      });
      navigator.serviceWorker.addEventListener('message', function(event) {
      });
  } else {
    console.log("serviceWorker not found")
  }
}

const shadow = host.attachShadow({ mode: 'open' });
const styleSlot = document.createElement("section");
shadow.appendChild(styleSlot);
const renderIn = document.createElement("div");
renderIn.setAttribute('role','none');
styleSlot.appendChild(renderIn);
const root = ReactDOM.createRoot(renderIn);
root.render(
  <StyleSheetManager target={styleSlot}>
    <SettingsProvider>
      <App {...{mode}}/>
    </SettingsProvider>
  </StyleSheetManager>
);
