import styled from 'styled-components';

const StyledApp = styled.div`
  position:relative;
  font-family: 'century_gothicregular';
  color: black;
  background: ${props=>props.css.backgroundGrey};
  text-align: center;
  width:100%;
  height:${props=>props.appHeight}px;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow:hidden;
  box-sizing:border-box;
  &>div {
    position:absolute;
    top:0;
    left:calc(50% - ${props=>props.appWidth/2}px);
    width:${props=>props.appWidth}px;
    height:100%;
  }
  * {
    box-sizing:border-box;
    user-select:none;
  }
`;
export default StyledApp;
