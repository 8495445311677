import React, { useContext, useEffect, useRef, useMemo, useState, useCallback } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import Lottie from "lottie-react";
import cross from "assets/X+Open.json";
import WindowStyled from './WindowStyled';
import Data from 'Data';

function Window({title,subtitle,text,onToggle}) {
  const { appState,setAppState } = useContext(SettingsContext);
  const crossRef=useRef(null);
  const openRef=useRef(null);
  const {discoverStep,poiStep,uiStep}=appState;
  const show=useMemo(()=>uiStep==='discover',[uiStep]);
  const open=useMemo(()=>discoverStep==='main',[discoverStep]);
  const poiSelected=useMemo(()=>poiStep!==null,[poiStep]);
  const [crossReady,setCrossReady]=useState(false);
  const handleCrossReady = useCallback(()=>{
    setCrossReady(true);
  }, [setCrossReady]);
  const handleToggle=()=>{
    setAppState(state=>{return{...state,discoverStep:state.discoverStep==='mainClose'?'main':'mainClose'}})
  }
  useEffect(()=>{
    if (crossReady && open!==openRef.current) {
      if (open) {
        crossRef.current.goToAndStop(crossRef.current.firstFrame + 0, true)
        crossRef.current.setDirection(1);
        crossRef.current.play();
      } else {
        crossRef.current.goToAndStop(crossRef.current.firstFrame + crossRef.current.totalFrames-1, true)
        crossRef.current.setDirection(-1);
        crossRef.current.play();
      }
      openRef.current=open;
    }
  },[open,crossReady]);
  return (
    <WindowStyled className={(open?' open':'')+(show && !poiSelected?' show':'')} css={Data.css} aria-hidden={poiSelected || uiStep!=='discover'}>
      <div>
        <h3 className='title'>
          {title}
        </h3>
        <div className='subtitle'>
          {subtitle}
        </div>
        <div className='close svg-shadow' onClick={handleToggle} aria-hidden>
          <Lottie lottieRef={crossRef} onDOMLoaded={handleCrossReady} animationData={cross} autoplay={false} loop={false}/>
        </div>
        <div className='text' tabIndex={-1}>
          <div>
            {text}
          </div>
        </div>
      </div>
    </WindowStyled>
  )
}
export default Window;
