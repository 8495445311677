import React, { useContext,useMemo,useCallback } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import Lottie from "lottie-react";
import idleBouton from "assets/IdleBouton_3.json";
import PoiStyled from './PoiStyled';
import Data from 'Data';
import Utils from 'components/Utils';
import {ReactComponent as ItemLine} from 'assets/item-line.svg';

function Poi({itemName,number,title,subtitle,left,top,side='left-top',closed=false}) {
  const { setAppState,appState,t,screenSize,experienceLevel } = useContext(SettingsContext);
  const {discoverStep,poiStep,uiStep,turning,playing}=appState;
  const show=useMemo(()=>!turning && uiStep==='discover' && poiStep===null,[uiStep,poiStep,turning]);
  const open=useMemo(()=>discoverStep==='main',[discoverStep]);
  const poiSelected=useMemo(()=>poiStep!==null,[poiStep]);
  const handleSelect=useCallback(()=>{
    if (!playing) {
      setAppState(state=>{
        if (Data.mode==='embed'){
          const d={
            event:'kimJonesSection',
            experienceLevel: experienceLevel[state.step],
            sectionCta:Utils.pc(t(title,'text',[],Data.defaultLang)),
          };
          console.log('datalayer push',d);
          if (window.dataLayer) window.dataLayer.push(d);
        }
        return {...state,poiStep:number,uiStep:'discover',previousStep:state.step};
      });
    }
  },[setAppState,t,playing,number,title,experienceLevel]);
  const factor=screenSize==='xs' ? 0.4 : 1;
  return (
  <PoiStyled className={(open?' open':'')+(poiSelected?' selected':'')} css={Data.css} aria-hidden={poiSelected || uiStep!=='discover'}>
    <button onClick={handleSelect} tabIndex={poiSelected || uiStep!=='discover'?-1:0} className={'poi '+side+(show?' show':'')} style={{transform:`translate3d(${factor*left}px,${factor*top}px,0) perspective(0) scale(1)`}}>
      <div className='line' aria-hidden>
        {screenSize!=='xs' && <div className='line-inner'>
          <ItemLine/>
        </div>}
        <div className='btn svg-shadow'>
          <Lottie animationData={idleBouton}/>
        </div>
      </div>
      {screenSize!=='xs' && <div className='subtitle'>
        {t(subtitle)}
      </div>}
      <h3 className='title'>
        <span>{t(title)}</span>
      </h3>
    </button>
  </PoiStyled>
  )
}
export default Poi;
