import React, { useState, createContext, useEffect, useRef, useCallback, useMemo } from "react";
import Data from "Data";
import Utils from "components/Utils";
import Trads from "data.json";
import _ from 'lodash';
import customFetch from "./CustomFetch";
import Preload from 'preload-it';
import mobile from 'is-mobile';

const preload = Preload();
preload.fetch=customFetch;
const fileList=[];
for (const nodeKey of Object.keys(Data.nodeParams)) {
  const node=Data.nodeParams[nodeKey];
  const xs=window.innerWidth<576;
  if (xs && (node.xs || node.xsOnly)) fileList.push(node.src);
  if (!xs && !node.xsOnly) fileList.push(node.src);
}
export const SettingsContext = createContext({});

const translate=(k,lang,type='text',replacements)=>{
  const trads=Trads.find(o=>o.key===k) || {trads:[]};
  const trad=trads ? trads.trads.find(o=>o.lang===lang) || trads.trads.find(o=>o.lang===Data.defaultLang) || {} : {};
  return Utils.parseWithLinks(trad[type] || k,replacements);
}

const initialState= {
  lang:Data.userLang,
  step:0,
  uiStep:'main',
  discoverStep:'main',
  poiStep:null,
  previousStep:0,
  nav:Data.mode==='embed' ? 'manifesto':'home',
  optIn:false,
  showOptIn:false,
  birthdate:new Date(1950, 0, 1),
  country:'us',
  turning:false,
  colors:{...Data.colors},
  playing:false,
  xpStarted:Data.mode==='embed' ? false : true,
  xsModalOpen:false,
  showTurnHelper:false,
  freeze3D:false,
};

const SettingsProvider = ({children})=>{
    const cameraStepRef=useRef(null);
    const [ transitionId, setTransitionId ] = useState(null);
    const [ screenSize, setScreenSize ] = useState("xxl");
    const [ ratio, setRatio ] = useState(1);
    const [ items, setItems ] = useState([]);
    const [ appWidth, setAppWidth ] = useState(0);
    const [ appHeight, setAppHeight ] = useState(0);
    const [ mainContainer, setMainContainer ] = useState(null);
    const [ dashboardWidth, setDashboardWidth ] = useState(0);
    const [ dashboardHeight, setDashboardHeight ] = useState(0);
    const [ dashboardLeft, setDashboardLeft ] = useState(0);
    const [ dashboardTop, setDashboardTop ] = useState(0);
    const [ dashboardBottom, setDashboardBottom ] = useState(0);
    const [ wpWidth, setWpWidth ] = useState(0);
    const [ wpHeight, setWpHeight ] = useState(0);
    const [ wpLeft, setWpLeft ] = useState(0);
    const [ wpTop, setWpTop ] = useState(0);
    const [ appState, setAppState ] = useState(initialState);
    const [ loadState, setLoadState ] = useState({
      roomReady:false,
      shoeReady:false,
      limitedReady:false,
      masterpieceReady:false,
      assetsReady:false,
      fullyLoaded:false,
    });
    const [ progress, setProgress ] = useState(0);
    const [ complete, setComplete ] = useState(false);
    useEffect(()=>{
      preload.oncomplete = items => {
        setComplete(true);
      }
      preload.onprogress = event => {
        setProgress(event.progress);
      }
      preload.onerror = item => {
        preload.loaded--;
      }
      preload.fetch(fileList).then(items => {
        setItems(items);
        console.log(items);
      });
    },[setProgress,setComplete]);
    const getBlobUrl=useCallback((url)=>{
      const item=items.find(o=>o.url===url);
      if (item && item.blobUrl) return item.blobUrl;
      return url;
    },[items]);
    const refs=useRef({
      camera:null,
      room:null,
      environment:null,
      shoe:null,
      limited:null,
      masterpiece:null,
    });
    const t=useCallback((k,type='text',replacements,lang=appState.lang)=>translate(k,lang,type,replacements),[appState.lang]);
    useEffect(()=>{
      if (mainContainer) {
        const resize=()=>{
          const mw=mainContainer.clientWidth;
          let size="xs";
          if (!mobile()) {
            if (mw>=576) size="sm";
            if (mw>=768) size="md";
            if (mw>=992) size="lg";
            if (mw>=1200) size="xl";
            if (mw>=1600) size="xxl";
          }
          const nw=Data[Data.mode][size].nominalWidth;
          const nh=Data[Data.mode][size].nominalHeight;
          const nominalRatio=nw/nh;
          const mh=window.innerHeight;
          const mhu=Data.mode==='embed' ? mh - Data.headerHeight[size] : mh;
          const tw=mhu*nominalRatio;
          const w=Math.min(tw,mw,nw);
          const mainRatio=w/nw;
          const h=w/nominalRatio;
          const aw=mw;
          const ah=mh;
          const dw=nw;
          const dh=nh;
          const vw=w*2.26;
          const vh=Data.mode==='embed' ? h*1.7+Data.headerHeight[size] : h*1.7;
          setAppWidth(aw);
          setAppHeight(ah);
          setDashboardWidth(dw);
          setDashboardHeight(dh);
          setDashboardLeft((aw-dw*mainRatio)/2);
          setDashboardTop(Data.mode==='embed' ? (ah-Data.headerHeight[size]-dh*mainRatio)/2 + Data.headerHeight[size] : (ah-dh*mainRatio)/2);
          setDashboardBottom(Data.mode==='embed' ? (ah-Data.headerHeight[size]-dh*mainRatio)/2 : (ah-dh*mainRatio)/2);
          setWpWidth(vw);
          setWpHeight(vh);
          setWpLeft((aw-vw)/2);
          setWpTop(Data.mode==='embed' ? (ah-vh)/2+50 : (ah-vh)/2);
          setRatio(mainRatio);
          setScreenSize(size);
        }
        const dResize=_.debounce(resize,100);
        resize();
        window.addEventListener('resize',dResize);
        return ()=>{
          window.removeEventListener('resize',dResize);
        }
      }
    },[setScreenSize,setRatio,mainContainer]);
    useEffect(()=>{
      const {step,uiStep,poiStep}=appState;
      const stepId=`${step}-${uiStep}-${poiStep===null?'none':poiStep}`;
      if (stepId!==cameraStepRef.current) {
        console.log(`${cameraStepRef.current}/${stepId}`);
        setTransitionId(`${cameraStepRef.current}/${stepId}`);
        cameraStepRef.current=stepId;
        if (step>0) {
          const items=[Data.items.shoe,Data.items.limited,Data.items.masterpiece];
          const item=t(items[step-1].discoverFrame.title,'text',[],Data.defaultLang).replace(/\s|\./g,'-').toLowerCase();
          const poi=t(poiStep!==null ? items[step-1].pois[poiStep].title : '','text',[],Data.defaultLang).replace(/\s|\./g,'-').toLowerCase();
          if (Data.mode==='retail') window.history.pushState({},"","/"+item+(uiStep==='discover'?'/'+uiStep : '')+(poi!=='' ? '/'+poi:''));
        } else {
          if (Data.mode==='retail') window.history.pushState({},"","/");
        }
      }
    },[appState,setTransitionId,t]);
    const experienceLevel=useMemo(()=>[
      'Welcome',
      t(Data.items.shoe.discoverFrame.title,'text',[],Data.defaultLang),
      t(Data.items.limited.discoverFrame.title,'text',[],Data.defaultLang),
      t(Data.items.masterpiece.discoverFrame.title,'text',[],Data.defaultLang),
    ],[t]);
    const threeReady= loadState.roomReady;
    const value={
      screenSize,
      appState,
      setAppState,
      refs,
      transitionId,
      appWidth,
      appHeight,
      dashboardWidth,
      dashboardHeight,
      dashboardLeft,
      dashboardTop,
      dashboardBottom,
      wpWidth,
      wpHeight,
      wpLeft,
      wpTop,
      t,
      ratio,
      initialState,
      complete,
      progress,
      loadState,
      setLoadState,
      threeReady,
      mainContainer,
      setMainContainer,
      getBlobUrl,
      experienceLevel,
    };
    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
