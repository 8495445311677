import React, { useContext,useMemo,useRef } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import {ReactComponent as Line} from 'assets/LINE-GRAPHIC-ELEMENTS.svg';
import Lottie from "lottie-react";
import clicBouton from "assets/PULSATING-GRAPHIC-ELEMENT.json";
import discoverIdle from "assets/DiscoverIDLE.json";
import DiscoverFrameStyled from './DiscoverFrameStyled';
import Data from 'Data';

function DiscoverFrame({title,subtitle,top}) {
  const { appState,t,screenSize,dashboardTop,ratio } = useContext(SettingsContext);
  const {uiStep} = appState;
  const anim=useRef(null);
  const hidden=useMemo(()=>uiStep==='discover',[uiStep]);
  return (
    <DiscoverFrameStyled className={(hidden?' hidden':'')} aria-hidden={hidden} css={Data.css} params={{dashboardTop,ratio}}>
      <div className='line' style={{transform:`translate3d(0,${screenSize==='xs' ? top : 0}px,0)`}}>
        {screenSize !== 'xs' && <Line/>}
        <Lottie animationData={clicBouton} className='svg-shadow anim'/>
      </div>
      <h3 className='title'>
        {t(title)}
      </h3>
      <div className='subtitle'>
        {t(subtitle)}
      </div>
      <button className='cta' style={{transform:`translate3d(0,${screenSize==='xs' ? top : 0}px,0)`}} tabIndex={hidden?-1:0}>
        <Lottie lottieRef={anim} autoplay={Data.mode==='retail'} animationData={discoverIdle} className='anim svg-shadow'/>
        <div className='cta-text' onMouseEnter={()=>Data.mode==='embed' && anim.current.play()} onMouseLeave={()=>Data.mode==='embed' && anim.current.stop()} >{screenSize === 'xs' ? t('tap-to-discover') : t('discover')}</div>
      </button>
    </DiscoverFrameStyled>
  )
}
export default DiscoverFrame;
