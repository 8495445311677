import styled from 'styled-components';

const WindowXsModalStyled = styled.div`
  position:absolute;
  top:${props => -props.params.top/props.params.ratio}px;
  left:${props => -props.params.left/props.params.ratio}px;
  width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
  height:${props => props.params.height + props.params.top/props.params.ratio + props.params.bottom/props.params.ratio}px;
  box-sizing:border-box;
  background-color:${props=>props.css.backgroundGrey};
  transition: opacity 1s;
  opacity:0;
  pointer-events: none;
  &.open {
    opacity:1;
    pointer-events: all;
  }
  &>div {
    position:absolute;
    left:${props => props.params.left/props.params.ratio}px;
    width:${props => props.params.width}px;
    height:${props => props.params.height - 10}px;
    bottom:${props => 50 +props.params.bottom/props.params.ratio}px;
    box-sizing:border-box;
    text-align:left;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    padding:13px;
    .close {
      position:absolute;
      right:-3px;
      top:-6px;
      width:35px;
      height:35px;
      transition: width 0.5s, height 0.5s;
      cursor:pointer;
    }
    .text {
      background-color:#FFF;
      box-shadow: 0 0 5px #FFF8;
      height:calc(100% - 9em);
      &>div {
        color:${props => props.css.darkGrey};
        padding:13px;
        text-align:left;
        font-size:16px;
        blockquote {
          text-transform:uppercase;
          margin-left:15px;
          padding-left:15px;
          border-left:2px solid ${props => props.css.darkGrey};
        }
        p:first-child {
          margin-top:0;
        }
        p:last-child {
          margin-bottom:0;
        }
      }
      overflow:scroll;
      box-sizing: border-box;
    }
    .title {
      position:relative;
      color:${props => props.css.darkGrey};
      font-size:24px;
      padding-right:30px;
      transition: font-size 0.5s;
      font-family:'hennessy_sansregular';
      font-weight:400;
      margin:0;
      margin-top:0;
      text-transform:uppercase;
      text-align:left;
    }
    .subtitle {
      color:${props => props.css.darkGrey};
      font-size:20px;
      transition: font-size 0.5s, line-height 0.5s, margin-bottom 0.5s;
      color:#FFF;
      text-transform:uppercase;
      text-align:left;
      line-height:1;
      margin-bottom:10px;
    }
  }
`;
export default WindowXsModalStyled;
