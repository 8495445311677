import styled from 'styled-components';

const SplashscreenStyled = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#FFF;
  pointer-events:none;
  opacity:0;
  transition:opacity 1s;
  &.visible {
    opacity:1;
    transition:opacity 1s 1s;
    pointer-events:all;
  }
  .welcome {
    position:absolute;
    top:190px;
    left:0;
    width:100%;
    opacity:0;
    transition:opacity 1s;
    font-size:30px;
    text-transform:uppercase;
    pointer-events:none;
  }
  &.visible .welcome {
    opacity:1;
  }
  .logos {
    position:absolute;
    top:calc( 50% - 113px);
    left:calc( 50% - 125px);
    width:250px;
    height:226px;
    margin-top:0;
    margin-bottom: 30px;
    transform: translate3d(0,0,0) scale(1);
    transition:transform 1s, opacity 1s;
    opacity:0;
    pointer-events:none;
    svg {
      width:100%;
      height:100%;
    }
  }
  .content {
    position:relative;
    opacity:0;
    pointer-events:none;
    transform: translate3d(0,100px,0);
    transition:opacity 1s, transform 1s;
    width:80%;
    padding:10px;
    .optinwarning {
      opacity:0;
      transition:opacity 1s;
      background-color:#FFF;
      box-shadow: 0 0 5px #FFF8;
      margin-bottom: 15px;
      color:${props=>props.css.darkGrey};
      position: relative;
      text-align: left;
      line-height:2;
      padding: 0 10px;
      .push-right {
        position:absolute;
        right:2px;
        top:0;
        height:2em;
        width:2em;
        background-color:#0000;
        border: none;
        padding:0;
        margin:0;
        font-size:inherit;
        cursor:pointer;
        svg {
          width:100%;
          height:100%;
        }
      }
      &.show {
        opacity:1;
      }
    }
    .cols {
      display:flex;
      .left {
        width:45%;
        margin-right:8px;
        flex-grow: 1;
        border: 1px solid #FFF;
        box-shadow: 0 0 5px #FFF8, inset 0 0 5px #FFF8;
      }
      .right {
        width:45%;
        border: 1px solid #FFF;
        box-shadow: 0 0 5px #FFF8, inset 0 0 5px #FFF8;
        margin-left:8px;
        flex-grow: 1;
        .body {
          padding:0;
        }
      }
    }
    .header {
      border-bottom: 1px solid #FFF;
      width:100%;
      padding:10px;
      font-family:'hennessy_sansregular';
      font-weight:400;
      font-size:20px;
      color:${props=>props.css.darkGrey};
      text-align:left;
      letter-spacing: 2%;
    }
    .body {
      width:100%;
      padding:10px;
      height:200px;
      overflow-y:scroll;
    }
  }
  .privacy {
    opacity:0;
    pointer-events:none;
    transform: translate3d(0,100px,0);
    transition:opacity 1s, transform 1s;
    font-size:20px;
    line-height:1.2em;
    text-transform:uppercase;
    user-select:none;
    width:80%;
    .cross {
      position:relative;
      display:inline-block;
      width: 24px;
      height: 24px;
      padding:0;
      transition:background-size 0.2s;
      margin-right: 10px;
      svg {
        position:absolute;
        top:0px;
        left:-3px;
        width:130%;
        height:130%;
        opacity:0;
        transition: opacity 0.5s;
      }
      &.optin {
        svg {
          transition: opacity 0.5;
          opacity:1;
        }
      }
      input[type="checkbox"] {
        position:absolute;
        top:0;
        left:0;
        cursor: pointer;
        appearance: none;
        background-color: #fff0;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.2em;
        height: 1.2em;
        border: 1px solid #FFF;
        box-shadow: 0 0 5px #FFF8, inset 0 0 5px #FFF8;
        transform: translateY(0.2em);
      }
    }
    button {
      cursor: pointer;
      font: inherit;
      color: currentColor;
      background-color:#FFF0;
      border:none;
      text-decoration: underline;
    }
  }
  .tapto {
    position:absolute;
    bottom:100px;
    width:100%;
    left:0;
    margin-top:50px;
    padding-bottom: 19px;
    font-size:16px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform:uppercase;
    opacity:0;
    pointer-events:none;
    .enter {
      position:absolute;
      top:70px;
      left:calc(50% - 150px);
      width:300px;
      transition:opacity 1s;
    }
    .confirm {
      position:absolute;
      top:70px;
      left:calc(50% - 150px);
      width:300px;
      transition:opacity 1s;
    }
    .btn {
      background-color:#0000;
      border:none;
      padding:0;
      margin:0;
      margin-bottom: 10px;
      width:60px;
      height:60px;
      cursor:pointer;
      pointer-events:none;
      transition:opacity 1s;
    }
  }
  .tapto-mask {
    position:absolute;
    top:${props => -props.params.top/props.params.ratio}px;
    left:${props => -props.params.left/props.params.ratio}px;
    width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
    height:${props => props.params.height + props.params.top/props.params.ratio + props.params.bottom/props.params.ratio}px;
    pointer-events:none;
    cursor:pointer;
  }
  .privacy-modal {
    position:absolute;
    top:272px;
    left:118px;
    right:118px;
    bottom:118px;
    border: 1px solid #FFF;
    box-shadow: 0 0 5px #FFF8, inset 0 0 5px #FFF8;
    padding:5px 13px;
    opacity:0;
    pointer-events:none;
    transition:opacity 1s;
    h3 {
      color:${props => props.css.darkGrey};
      text-align:left;
      padding:0;
      margin:0;
    }
    .text {
      position:absolute;
      top:2.2em;
      left:13px;
      right:13px;
      bottom:13px;
      background-color:#FFF;
      box-shadow: 0 0 5px #FFF8;
      &>div {
        color:${props => props.css.darkGrey};
        padding:13px;
        text-align:left;
        font-size:16px;
        blockquote {
          text-transform:uppercase;
          margin-left:15px;
          padding-left:15px;
          border-left:2px solid ${props => props.css.darkGrey};
        }
        p:first-child {
          margin-top:0;
        }
        p:last-child {
          margin-bottom:0;
        }
      }
      transition: height 0.5s;
      overflow-y:scroll;
      box-sizing: border-box;
    }
    .close {
      position:absolute;
      right:15px;
      top:-3px;
      width:30px;
      height:30px;
      cursor:pointer;
    }
    box-sizing: border-box;
  }
  .manifesto-modal {
    position:absolute;
    top:${props => -props.params.bottom/props.params.ratio}px;
    left:0;
    width:100%;
    height:${props => props.params.height + 2*props.params.bottom/props.params.ratio}px;
    text-align:left;
    opacity:0;
    pointer-events:none;
    transition:opacity 1s;
    box-sizing: border-box;
    .manifesto-container {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      opacity:1;
      transition:opacity 2s;
      &.faded {
        opacity:0;
      }
      .manifesto-inner {
        position:absolute;
        top:118px;
        left:118px;
        right:118px;
        bottom:118px;
        .Typewriter__wrapper {
          text-transform:uppercase;
          font-size:26px;
          letter-spacing:0.1em;
        }
        .Typewriter__cursor {
          font-size:24px;
          display:inline-block;
          position:relative;
          width:0.6em;
          height:1em;
          border:none;
          top:4px;
          left:5px;
          background-color:#FFF;
          color:#0000;
        }
        &.paused {
          .Typewriter__cursor {
            animation:1s linear 0s infinite normal blink;
          }
        }
      }
      .skip-intro {
        position:absolute;
        bottom:30px;
        right:30px;
        display:flex;
        align-items:center;
        cursor:pointer;
        opacity:0;
        pointer-events:none;
        transition:opacity 1s;
        span {
          font-size:26px;
          text-transform:uppercase;
        }
        button {
          border:none;
          background:none;
          padding:none;
          padding:0 5px;
          cursor:pointer;
        }
        &:hover .next {
          animation: 1s linear 0s infinite next;
        }
        .xs &:hover .next {
          animation: none;
        }
      }
    }
  }
  .loader-modal {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    opacity:0;
    pointer-events:none;
    transition:opacity 1s;
    .loader-inner {
      flex-direction:column;
      justify-content:center;
      align-items:center;
      opacity:1;
      transition:opacity 1s 1s;
      &.started {
        opacity:0;
      }
      .bottle {
        opacity:1;
        transition:opacity 1s 1s;
        text-transform:uppercase;
        &.complete {
          opacity:0;
        }
        svg {
          width:300px;
          height:400px;
          .progress-line {
            stroke-dasharray: 2290;
            stroke-dashoffset: 2290;
          }
        }
      }
      .starting {
        position:relative;
        width:120px;
        height:120px;
        display:flex;
        justify-content:center;
        align-items:center;
        text-transform:uppercase;
        svg {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          transform:rotate(-90deg);
          path {
            animation: 1s circle;
          }
        }
      }
    }
  }
  .xs & .logos {
    width:200px;
    height:181px;
    top:calc( 50% - 90.5px);
    left:calc( 50% - 100px);
  }
  .xs & .tapto {
    bottom:50px;
  }
  .xs & .welcome {
    top:120px;
    font-size:24px;
  }
  .xs & .loader-modal {
    .loader-inner {
      .bottle {
        svg {
          width:150px;
          height:220px;
        }
      }
    }
  }
  .xs & .manifesto-modal {
    .manifesto-container {
      .manifesto-inner {
        top:15px;
        left:15px;
        bottom:15px;
        right:15px;
        .Typewriter__wrapper {
          text-transform:uppercase;
          font-size:12px;
          letter-spacing:0.1em;
        }
        .Typewriter__cursor {
          font-size:12px;
          display:inline-block;
          position:relative;
          width:0.6em;
          height:1em;
          border:none;
          top:4px;
          left:5px;
          background-color:#FFF;
          color:#0000;
        }
      }
      .skip-intro {
        span {
          font-size:12px;
        }
        button {
          svg {
            height:20px;
            width:20px;
          }
        }
      }
    }
  }

  &.visible.home {
    .logos {
      opacity:1;
      transition:transform 0, opacity 1s ;
    }
    .tapto {
      opacity:1;
      .enter {
        opacity:1;
        pointer-events:all;
      }
      .confirm {
        opacity:0;
        pointer-events:none;
      }
      .btn {
        opacity:1;
        pointer-events:all;
      }
    }
    .tapto-mask {
      pointer-events:all;
    }
  }
  &.visible.form {
    .content, .privacy {
      pointer-events:all;
      opacity:1;
      transform: translate3d(0,0,0);
    }
    .welcome {
      opacity:0;
      pointer-events:none;
    }
    .logos {
      opacity:1;
      transform: translate3d(0,calc(-240px),0) scale(0.6);
      &.warning {
        transform: translate3d(0,calc(-270px),0) scale(0.6);
      }
    }
    .tapto {
      opacity:1;
      pointer-events:all;
      .enter {
        opacity:0;
        pointer-events:none;
      }
      .confirm {
        opacity:1;
        pointer-events:all;
      }
      .btn {
        opacity:1;
        pointer-events:all;
      }
    }
  }
  &.visible.privacy {
    .welcome {
      opacity:0;
      pointer-events:none;
    }
    .content, .privacy {
      opacity:0;
      pointer-events:none;
    }
    .privacy-modal {
      opacity:1;
    }
    .logos {
      transform: translate3d(0,calc(-240px),0) scale(0.6);
    }
    .close {
      pointer-events:all;
    }
    .text {
      pointer-events:all;
    }
    .tapto {
      .enter {
        opacity:0;
        pointer-events:none;
      }
      .confirm {
        opacity:0;
        pointer-events:none;
      }
      .btn {
        opacity:0;
        pointer-events:none;
      }
    }
  }
  &.visible.manifesto {
    .welcome {
      opacity:0;
      pointer-events:none;
    }
    .logos {
      opacity:0;
    }
    .content, .privacy {
      opacity:0;
      pointer-events:none;
    }
    .privacy-modal {
      opacity:0;
      pointer-events:none;
    }
    .manifesto-modal {
      opacity:1;
    }
    .skip-intro {
      opacity:1;
      pointer-events:all;
    }
    .close {
      pointer-events:none;
    }
    .tapto {
      .enter {
        opacity:0;
        pointer-events:none;
      }
      .confirm {
        opacity:0;
        pointer-events:none;
      }
      .btn {
        opacity:0;
        pointer-events:none;
      }
    }
  }
  &.visible.loading {
    .welcome {
      opacity:0;
      pointer-events:none;
    }
    .logos {
      opacity:0;
    }
    .content, .privacy {
      opacity:0;
      pointer-events:none;
    }
    .privacy-modal {
      opacity:0;
      pointer-events:none;
    }
    .manifesto-modal {
      opacity:0;
      pointer-events:none;
    }
    .loader-modal {
      opacity:1;
      pointer-events:all;
    }
    .skip-intro {
      opacity:0;
      pointer-events:none;
    }
    .close {
      pointer-events:none;
    }
    .tapto {
      .enter {
        opacity:0;
        pointer-events:none;
      }
      .confirm {
        opacity:0;
        pointer-events:none;
      }
      .btn {
        opacity:0;
        pointer-events:none;
      }
    }
    .tapto-mask {
      pointer-events:none;
    }
  }
  .xs & .content {
    display:none;
  }
  @keyframes blink {
    0% {opacity:1;}
    45% {opacity:1;}
    50% {opacity:0;}
    95% {opacity:0;}
    100% {opacity:1;}
  }
  @keyframes pulse {
    0% {border:2px solid #FFF0;box-shadow: 0 0 5px #FFF0, inset 0 0 5px #FFF0;}
    100% {border:2px solid #FFFF;box-shadow: 0 0 5px #FFF8, inset 0 0 5px #FFF8;}
  }
  @keyframes circle {
    0% {  stroke-dasharray: 100;stroke-dashoffset: 100;  }
    100% { stroke-dasharray: 100;stroke-dashoffset: 0; }
  }
`;
export default SplashscreenStyled;
