import React, {useState, useContext, useRef} from 'react';
import Typewriter from 'typewriter-effect';
import {ReactComponent as Fleche} from 'assets/NAVIGATION_ARROWS.svg';
import { SettingsContext } from 'providers/SettingsProvider';
import Data from 'Data';

const speed=10;
const mainDelay=2000;

function Manifesto({text, onDone}) {
  const { t,appState,setAppState } = useContext(SettingsContext);
  const { step,nav } = appState;
  const [ paused,setPaused ] = useState(true);
  const [ faded,setFaded ] = useState(false);
  const setFreeze3D=(freeze3D)=>setAppState(state=>{return{...state,freeze3D}});
  const typing=useRef();
  const handleSkip=()=>{
    typing.current.stop();
    setFreeze3D(false)
    setFaded(true);
    setTimeout(onDone,2000);
    if (Data.mode==='embed'){
      const d={
        event:'kimJonesCtaClick',
        ctaName:t('skip-intro','text',[],Data.defaultLang),
        experienceLevel: 'Intro',
      };
      console.log('datalayer push',d);
      if (window.dataLayer) window.dataLayer.push(d);
    }
  };
  return  <div className={'manifesto-container'+(faded?' faded':'')}>
    <div className={'manifesto-inner'+(paused?' paused':'')}>
    {text && <Typewriter
      onInit={(typewriter) => {
        const lines=text.split('\n');
        const tw=typewriter.changeDelay(speed).pauseFor(mainDelay);
        let first=true;
        setFreeze3D(true);
        for (const line of lines) {
          tw.callFunction(()=>setPaused(false)).typeString((first? '':'<br/><br/>')+line).callFunction(()=>setPaused(true)).pauseFor(1500);
          first=false;
        }
        tw.pauseFor(2500)
        .callFunction(()=>setFaded(true))
        .pauseFor(2500)
        .callFunction(()=>setFreeze3D(false))
        .callFunction(onDone)
        .start();
        typing.current=tw;
      }}
    />}
    </div>
    <div className='skip-intro' onClick={handleSkip}>
      <span className="info">{t('skip-intro')}</span>
      <button className="next" aria-label={t('skip-intro')} tabIndex={nav==='manifesto' && step===0 ? 0 : -1}><Fleche aria-hidden/></button>
    </div>
  </div>;
}
export default Manifesto;
