import React, { useContext, useCallback } from 'react'
import Salle from "components/Salle";
import Ui from "components/Ui";
import AppStyled from './AppStyled'
import { SettingsContext } from 'providers/SettingsProvider';
import Data from 'Data';

function App() {
  const { setMainContainer, appHeight, appWidth } = useContext(SettingsContext);
  const setMainContainerRef = useCallback(node => {
    if (node !== null) {
      setMainContainer(node);
    }
  }, [setMainContainer]);
  return (
      <AppStyled ref={setMainContainerRef} className={Data.mode} css={Data.css} appHeight={appHeight} appWidth={appWidth}>
        <div>
          <Salle/>
          <Ui />
        </div>
      </AppStyled>
  );
}

export default App;
