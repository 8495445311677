import styled from 'styled-components';

const OverlayStyled = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  user-select:none;
  .turn {
    position:absolute;
    bottom:50px;
    text-transform:uppercase;
    line-height:36px;
    display:flex;
    align-items:center;
    color:#FFF;
    div {
      display:inline-block;
      width:36px;
      height:36px;
      margin-right:10px;
    }
    opacity:0;
    left:50%;
    transition: opacity 1s, left 1s;
    pointer-events:none;
    &.visible {
      opacity:1;
      left:70%;
      pointer-events:all;
    }
  }
  .turn-mask {
    position:absolute;
    top:50px;
    bottom:50px;
    right:50px;
    left:50%;
    pointer-events:none;
    &.visible {
      pointer-events:all;
    }
  }
`;
export default OverlayStyled;
