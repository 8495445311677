import styled from 'styled-components';

const WindowStyled = styled.div`
  position:absolute;
  left:60px;
  top:50px;
  width:348px;
  height:calc(95px);
  transition: height 0.5s, width 0.5s, opacity 0.5s;
  display:flex;
  flex-direction: column;
  justify-content: center;
  opacity:0;
  pointer-events: none;
  &.show {
    transition: height 0.5s, width 0.5s, opacity 0.5s 0.5s;
    opacity:1;
    pointer-events: all;
  }
  &>div {
    position:relative;
    text-align:right;
    width:100%;
    transition: opacity 0.5s;
    border: 1px solid #FFF;
    box-shadow: 0 0 5px #FFF8, inset 0 0 5px #FFF8;
    padding:13px;
    .close {
      position:absolute;
      right:10px;
      top:10px;
      width:35px;
      height:35px;
      transition: width 0.5s, height 0.5s;
      cursor:pointer;
    }
    .text {
      background-color:#FFF;
      box-shadow: 0 0 5px #FFF8;
      &>div {
        color:${props => props.css.darkGrey};
        padding:13px;
        text-align:left;
        font-size:16px;
        blockquote {
          text-transform:uppercase;
          margin-left:15px;
          padding-left:15px;
          border-left:2px solid ${props => props.css.darkGrey};
        }
        p:first-child {
          margin-top:0;
        }
        p:last-child {
          margin-bottom:0;
        }
      }
      height:0;
      transition: height 0.5s;
      overflow:hidden;
      box-sizing: border-box;
    }
    .line {
      position:absolute;
      top:0;
      right:0;
    }
    .title {
      color:${props => props.css.darkGrey};
      font-size:20px;
      padding-right:30px;
      transition: font-size 0.5s;
      font-family:'hennessy_sansregular';
      font-weight:400;
      margin:0;
      margin-top:0;
      text-transform:uppercase;
      text-align:left;
    }
    .subtitle {
      color:${props => props.css.darkGrey};
      font-size:16px;
      transition: font-size 0.5s, line-height 0.5s, margin-bottom 0.5s;
      color:#FFF;
      text-transform:uppercase;
      text-align:left;
      line-height:1.2;
      margin-bottom:0;
    }
    .cta {
      cursor:pointer;
    }
  }
  &.open {
    width:448px;
    height:calc(100% - 100px);
    transition: height 0.5s 0.5s, width 0.5s 0.5s, opacity 0.5s;
    &.show {
      transition: height 0.5s 0.5s, width 0.5s 0.5s, opacity 0.5s 0.5s;
    }
    .title {
      transition: font-size 0.5s 0.5s;
      font-size:30px;
    }
    .close {
      transition: width 0.5s, height 0.5s, opacity 0.5s ;
      width:30px;
      height:30px;
    }
    .subtitle {
      font-size:26px;
      line-height:0.7;
      margin-bottom:13px;
      transition: font-size 0.5s 0.5s, line-height 0.5s 0.5s, margin-bottom 0.5s 0.5s;
    }
    .text {
      transition: height 0.5s 0.5s;
      height:383px;
      overflow-y:scroll;
    }
  }
`;
export default WindowStyled;
