import styled from 'styled-components';

const StartStyled = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:
  pointer-events:all;
  video {
    position:absolute;
    top:${props => -props.params.top/props.params.ratio}px;
    left:${props => -props.params.left/props.params.ratio}px;
    width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
    height:${props => props.params.height + props.params.top/props.params.ratio + props.params.bottom/props.params.ratio}px;
    object-fit:cover;
  }
  h1 {
    position:absolute;
    right:${props => -props.params.left/props.params.ratio + 150 }px;
    bottom:400px;
    width:40vw;
    font-family:'hennessy_sansregular';
    font-weight:400;
    text-align:right;
    font-size:4.167vw;
    line-height:1;
    margin:0;
    text-transform:uppercase;
  }
  .btn-container {
    position:absolute;
    bottom:320px;
    right:${props => -props.params.left/props.params.ratio + 150 }px;
    button {
      font-size:12px;
      background-color:#FFF;
      border:none;
      font-family:'hennessy_sansregular';
      font-weight:400;
      width:294px;
      padding: 14px 20px;
      pointer-events:all;
      cursor:pointer;
      text-transform:uppercase;
      transition: all 0.2s;
      border:2px solid #FFF;
      &:hover {
        background: #4d4d4d;
        color: #ffffff;
        transition: all 0.2s;
      }
    }
  }
  .xs & {
    h1 {
      position:absolute;
      top:${props => -props.params.bottom/props.params.ratio + 30}px;
      left:${props => -props.params.left/props.params.ratio}px;
      width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
      font-family:'hennessy_sansregular';
      font-weight:400;
      font-size:35px;
      text-transform:uppercase;
      text-align:center;
      padding: 0 15px;
      margin:0;
    }
    .btn-container {
      position:absolute;
      top:auto;
      bottom:${props => -props.params.bottom/props.params.ratio + 15}px;
      left:${props => -props.params.left/props.params.ratio}px;
      width:${props => props.params.width + 2*props.params.left/props.params.ratio}px;
      button {
        font-size:12px;
        background-color:#FFF;
        color:${props => props.css.darkGrey};
        text-decoration:none;
        border:none;
        font-family:'hennessy_sansregular';
        font-weight:400;
        padding: 10px 50px;
        pointer-events:all;
        cursor:pointer;
        text-transform:uppercase;
        transition: all 0.2s;
        border:2px solid #FFF;
        &:hover {
          background: #4d4d4d;
          color: #ffffff;
          transition: all 0.2s;
        }
      }
    }
  }
`;
export default StartStyled;
