import React, { useContext, useMemo, useCallback, useRef } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import Lottie from "lottie-react";
import idleBouton from "assets/IdleBouton.json";
import SplashscreenStyled from './SplashscreenStyled';
import Countries from 'components/Ui/components/Countries';
import Age from 'components/Ui/components/Age';
import Loader from './Loader';
import Manifesto from './Manifesto';
import Data from 'Data';
import Utils from "components/Utils";
import {ReactComponent as Logos} from 'assets/MAIN_LOGO.svg';
import {ReactComponent as Cross} from 'assets/CROSS_ICON_black.svg';
import {ReactComponent as CheckCross} from 'assets/CROSS_ICON.svg';

function Splashscreen({className}) {
  const { experienceLevel, appState, setAppState, t, loadState, ratio, dashboardWidth, dashboardHeight, dashboardLeft, dashboardTop, dashboardBottom} = useContext(SettingsContext);
  const {optIn,nav,showOptIn,country,birthdate,step}=appState;
  const timer=useRef(0);
  const handleNavTo=useCallback((nav)=>{
    setAppState(state=>{return {...state,nav}});
  },[setAppState]);
  const replacements=useMemo(()=>[
    {id:'privacy-policy',replacement:<button onClick={()=>handleNavTo('privacy')}>{t('privacy-policy')}</button>},
  ],[t,handleNavTo]);
  const handleChange=(e)=>{
    setAppState(state=>{return {...state,optIn:e.target.checked}});
  }
  const handleCountryChange=(country)=>{
    setAppState(state=>{return {...state,country}});
  }
  const handleBirthdateChange=(birthdate)=>{
    setAppState(state=>{return {...state,birthdate}});
  }
  const action=()=>{
    clearTimeout(timer.current);
    setAppState(state=>{
      const step=Data.mode==='embed' ? (state.nav==='manifesto' ? 0 : 1 ): (state.nav==='form' && state.optIn && country && Utils.canDrink(country,birthdate) ? 1 : 0);
      if(step>0) {
        if (Data.mode==='embed'){
          const d={
            event:'kimJonesCtaClick',
            ctaName:'Tap to enter',
            experienceLevel:experienceLevel[state.step],
          };
          console.log('datalayer push',d);
          if (window.dataLayer) window.dataLayer.push(d);
        }
      }
      const showOptIn=state.nav==='form' && (!state.optIn || !state.country || !Utils.canDrink(country,birthdate)) ? true : state.showOptIn;
      if (showOptIn) timer.current=setTimeout(()=>setAppState(state=>{return{...state,showOptIn: false}}),5000);
      return{
        ...state,
        step,
        nav:Data.mode==='embed' ? 'home' : (state.nav==='manifesto' ? 'home' : (state.nav==='home' ? 'form' : state.nav)),
        showOptIn,
      }
    });
  }
  const close=()=>{
    setAppState(state=>{return{
      ...state,
      showOptIn: false,
    }});
  }
  const width=dashboardWidth;
  const height=dashboardHeight;
  const left=dashboardLeft;
  const top=dashboardTop;
  const bottom=dashboardBottom;
  return (
    <SplashscreenStyled className={className+' '+nav+(!loadState.fullyLoaded ? ' loading' : '')} css={Data.css} params={{width,height,left,top,bottom,ratio}}>
      <div className='welcome' aria-hidden={nav!=='home' || step>0}>{t('welcome-to')}</div>
      <div className={'logos'+(showOptIn? ' warning':'')} aria-hidden={(nav!=='home' && nav!=='form') || step>0}>
        <Logos role="img" aria-label="Hennessy X Kim Jones"/>
      </div>
      <div className='content' aria-hidden={nav!=='form' || step>0} tabIndex={-1}>
        <div className={'optinwarning'+(showOptIn? ' show':'')} aria-hidden={!showOptIn}>{t('optin-warning','html')}<span className="push-right" onClick={close}><Cross/></span></div>
        <div className='cols'>
          <div className='left'>
            <div className='header'>
              {t('country-region')}
            </div>
            <div className='body' tabIndex={-1}>
              <Countries value={country} onChange={handleCountryChange}/>
            </div>
          </div>
          <div className='right'>
            <div className='header'>
              {t('date-of-birth')}
            </div>
            <div className='body'>
              <Age value={birthdate} onChange={handleBirthdateChange}/>
            </div>
          </div>
        </div>
      </div>
      <div className='privacy' aria-hidden={nav!=='form' || step>0}>
        <div className={'cross'+(optIn?' optin':'')}>
          <CheckCross/>
          <input type='checkbox' name='privacy-policy' onChange={handleChange} checked={optIn} tabIndex={step===0 && nav==='form' ? 0 : -1}/>
        </div>
        {t('i-agree','text',replacements)}
      </div>
      <div className='privacy-modal' aria-hidden={nav!=='privacy' || step>0}>
        <div className='close' onClick={()=>handleNavTo('form')}><CheckCross/></div>
        <h3>{t('privacy-policy')}</h3>
        <div className="text" tabIndex={-1}><div>{t('privacy-policy','html')}</div></div>
      </div>
      {Data.mode==='embed' && <div className='manifesto-modal' aria-hidden={nav!=='manifesto' || step>0}>
        {loadState.fullyLoaded && <Manifesto text={t('manifesto','html')} onDone={action}/>}
      </div>}
      <div className='loader-modal' aria-hidden={loadState.fullyLoaded}>
        <Loader onDone={action}/>
      </div>
      <div className='tapto' aria-hidden={!(loadState.fullyLoaded && (nav==='home' || nav==='form')) || step>0}>
        <button className='btn svg-shadow' onClick={action} aria-label={nav==='home' ? t('tap-to-enter') : t('tap-to-confirm')} tabIndex={loadState.fullyLoaded && (nav==='home' || nav==='form') && step===0 ? 0 : -1}>
          <Lottie animationData={idleBouton} aria-hidden/>
        </button>
        <div className="enter" aria-hidden={nav!=='home'}>{t('tap-to-enter')}</div>
        <div className="confirm" aria-hidden={nav!=='form'}>{t('tap-to-confirm')}</div>
      </div>
      {step===0 && nav==='home' && <div className='tapto-mask' onClick={action} aria-hidden tabIndex={-1}></div>}
    </SplashscreenStyled>
  )
}
export default Splashscreen;
