import React, { useContext, useEffect, useCallback, useState } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import {ReactComponent as Fleche} from 'assets/NAVIGATION_ARROWS.svg';
import { useSwipeable } from 'react-swipeable';
import Lottie from "lottie-react";
import selectionLangues from "assets/SelectionLangues.json";
import NavStyled from './NavStyled';
import Data from 'Data';

function Nav({className}) {
  const { experienceLevel, setAppState,appState,t, dashboardLeft, dashboardBottom, ratio, dashboardWidth } = useContext(SettingsContext);
  const { step,uiStep,playing } = appState;
  const [ langOpen,setLangOpen ] = useState(false);
  const handleLangChange=useCallback((lang)=>()=>{
    setAppState(state=>{
      if (Data.mode==='embed'){
        const d={
          event:'kimJonesCtaClick',
          ctaName:'Language',
          langSelected:lang,
          experienceLevel: experienceLevel[state.step],
        };
        console.log('datalayer push',d);
        if (window.dataLayer) window.dataLayer.push(d);
      }
      return {...state,lang}
    });
    setLangOpen(false);
  },[setAppState,experienceLevel]);
  const next=useCallback(()=>{
    if (!playing && uiStep==='main') setAppState(state=>{
      if (Data.mode==='embed'){
        const d={
          event:'kimJonesCtaClick',
          ctaName:'Right Arrow',
          experienceLevel: experienceLevel[state.step],
        };
        console.log('datalayer push',d);
        if (window.dataLayer) window.dataLayer.push(d);
      }
      const step=state.step===3 ? 1 : state.step+1;
      return {...state,step}
    });
  },[setAppState,uiStep,playing,experienceLevel]);
  const prev=useCallback(()=>{
    if (!playing && uiStep==='main') setAppState(state=>{
      if (Data.mode==='embed'){
        const d={
          event:'kimJonesCtaClick',
          ctaName:'Left Arrow',
          experienceLevel: experienceLevel[state.step],
        };
        console.log('datalayer push',d);
        if (window.dataLayer) window.dataLayer.push(d);
      }
      const step=state.step===1 ? 3 : state.step-1;
      return {...state,step}
    });
  },[setAppState,uiStep,playing,experienceLevel]);
  const { ref: documentRef,onMouseDown } = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    preventDefaultTouchmoveEvent: true,
    trackMouse:Data.mode==='embed',
  });
  // attach swipeable to document
  useEffect(() => {
    documentRef(document);
    document.addEventListener('mousedown',onMouseDown);
    // Clean up swipeable event listeners
    return () => {
      document.removeEventListener('mousedown',onMouseDown);
      documentRef({});
    }
  },[documentRef,onMouseDown]);
  const left=dashboardLeft;
  const bottom=dashboardBottom;
  const width=dashboardWidth;
  return (
    <NavStyled  aria-hidden={step===0 || uiStep!=='main'} className={className+(uiStep==='main' ? ' show':'')} params={{width,left,bottom,ratio}} css={Data.css}>
      <button className="prev" onClick={prev} tabIndex={step===0 || uiStep!=='main' ? -1 : 0}><Fleche/></button>
      <span className="info">{t('swipe-to-explore')}</span>
      <button className="next" onClick={next} tabIndex={step===0 || uiStep!=='main' ? -1 : 0}><Fleche/></button>
      {!Data.langAsked && <div className='lang-selector svg-shadow' onClick={()=>setLangOpen(state=>!state)}>
        <Lottie animationData={selectionLangues}/>
      </div>}
      {!Data.langAsked && <div className={'langs'+(langOpen ? ' open' : '')}>
        <div className='front'>
          {Data.langs.map((lang)=><span className='lang' key={lang.code} onClick={handleLangChange(lang.code)}>{t(lang.label)}</span>)}
        </div>
      </div>}
    </NavStyled>
  )
}
export default Nav;
