import styled from 'styled-components';

const PoiStyled = styled.div`
  position:absolute;
  left:50%;
  top:0;
  width:50%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition:transform 1s 0.5s;
  transform:translate3d(-45%,0,0);
  &.open {
    transition:transform 1s;
    transform:translate3d(0,0,0);
  }
  .poi {
    position:relative;
    background-color:#0000;
    font-family: 'century_gothicregular';
    border:none;
    padding:0;
    margin:0;
    text-align:right;
    opacity:0;
    transition: opacity 0.5s;
    pointer-events: none;
    cursor:pointer;
    .line {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      .btn {
        position:absolute;
        top:12px;
        left:-15px;
        width:40px;
        height:40px;
        pointer-events: none;
      }
      .line-inner {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        overflow:hidden;
      }
    }
    &.show {
      transition: opacity 0.5s 0.5s;
      pointer-events: all;
      opacity:1;
      .line {
        .btn {
          pointer-events: all;
        }
      }
    }
    .title {
      display:inline-block;
      color:${props => props.css.darkGrey};
      font-size:12px;
      margin:0;
      margin-top:0;
      min-width:80px;
      span {
        display:inline-block;
        background-color:#FFF;
        text-transform: uppercase;
        padding:5px 10px;
      }
    }
    .subtitle {
      color:#FFF;
      font-size:12px;
      margin-top:10px;
      color:#FFF;
      text-transform: uppercase;
      min-width:70px;
    }
    &.right-top {
      padding:0 0 15px 50px;
    }
    &.left-top {
      padding:0 50px 15px 0;
      text-align:left;
      .line {
        transform:scaleX(-1);
      }
    }
    &.left-bottom {
      padding:0 50px 15px 0;
      text-align:left;
      .line {
        transform:scale(-1);
      }
    }
    &.right-bottom {
      padding:0 0 15px 50px;
      .line {
        transform:scaleY(-1);
      }
    }
  }
  &.selected {
    opacity:0;
    pointer-events: none;
  }
  .xs & {
    left:0;
    top:0;
    width:100%;
    height:100%;
    .poi {
      width:30px;
      height:30px;
      .line {
        .btn {
          position:absolute;
          top:-5px;
          left:-15px;
          width:40px;
          height:40px;
          pointer-events:none;
        }
      }
      &.right-top {
        padding:0 0 0 30px;
        text-align:left;
        .title {
          position:absolute;
          left:30px;
        }
      }
      &.left-top {
        padding:0 30px 0 0;
        text-align:right;
        .title {
          position:absolute;
          right:30px;
        }
        .line {
          transform:scaleX(-1);
        }
      }
      &.left-bottom {
        padding:0 30px 0 0;
        text-align:right;
        .title {
          position:absolute;
          right:30px;
        }
        .line {
          transform:scale(-1);
        }
      }
      &.right-bottom {
        padding:0 0 0 30px;
        text-align:left;
        .title {
          position:absolute;
          left:30px;
        }
        .line {
          transform:scaleY(-1);
        }
      }
    }
    &.show {
      pointer-events: all;
      opacity:1;
      .poi {
        .line {
          .btn {
            pointer-events: all;
          }
        }
      }
    }
  }
`;
export default PoiStyled;
