import React, { useContext } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import {ReactComponent as Fleche} from 'assets/NAVIGATION_ARROWS_custom.svg';
import ExploreStyled from './ExploreStyled';
import Data from 'Data';

function Nav({className=''}) {
  const { t, experienceLevel, dashboardLeft, dashboardBottom, ratio, dashboardWidth, appHeight, appState } = useContext(SettingsContext);
  const { step } = appState;
  const handleExplore=()=>{
    const top=appHeight;
    window.scrollTo({top,behavior:'smooth'});
    if (Data.mode==='embed'){
      const d={
        event:'kimJonesCtaClick',
        ctaName:t('explore-content','text',[],Data.defaultLang),
        experienceLevel: experienceLevel[step],
      };
      console.log('datalayer push',d);
      if (window.dataLayer) window.dataLayer.push(d);
    }
  }
  const left=dashboardLeft;
  const bottom=dashboardBottom;
  const width=dashboardWidth;
  return (
    <ExploreStyled aria-hidden={step===0} className={className+(step>0 ? ' visible':'')} params={{width,left,bottom,ratio}} css={Data.css}>
      <button className="next" aria-label={t('explore-content')} onClick={handleExplore}><Fleche aria-hidden/></button>
      <span className="info" aria-hidden onClick={handleExplore}>{t('explore-content')}</span>
    </ExploreStyled>
  )
}
export default Nav;
