import styled from 'styled-components';

const PoiFocusStyled = styled.div`
position:absolute;
left:60px;
top:170px;
width:448px;
display:flex;
justify-content: center;
align-items: center;
pointer-events: none;
opacity:0;
pointer-events: none;
transition:opacity 1s;
.poifocus {
  position:relative;
  width:448px;
  text-align:left;
  border: 1px solid #FFF;
  box-shadow: 0 0 5px #FFF8, inset 0 0 5px #FFF8;
  padding:13px;
  .line {
    position:absolute;
    top:30px;
    left:calc(100% + 10px);
    width:203px;
    height:100px;
    transform:scaleX(-1);
    .btn {
      position:absolute;
      top:8px;
      left:-30px;
      width:60px;
      height:60px;
      pointer-events: none;
    }
    .line-inner {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      overflow:hidden;
    }
  }
  .title {
    color:${props=>props.css.darkGrey};
    font-size:30px;
    font-family:'hennessy_sansregular';
    font-weight:400;
    margin:0;
    margin-top:0;
    line-height:1;
    text-align:left;
  }
  .subtitle {
    color:${props=>props.css.darkGrey};
    font-size:26px;
    transition: font-size 0.5s;
    color:#FFF;
    line-height:1.2;
    margin-bottom:13px;
    text-align:left;
    text-transform:uppercase;
  }
  .text {
    background-color:#FFF;
    box-shadow: 0 0 5px #FFF8;
    &>div {
      color:${props=>props.css.darkGrey};
      padding:13px;
      text-align:left;
      font-size:16px;
      blockquote {
        text-transform:uppercase;
        margin-left:15px;
        padding-left:15px;
        border-left:2px solid ${props=>props.css.darkGrey};
      }
      p:first-child {
        margin-top:0;
      }
      p:last-child {
        margin-bottom:0;
      }
    }
    box-sizing: border-box;
  }
}
&.selected {
  opacity:1;
}
.xs & {
  position:absolute;
  left:0;
  top:auto;
  bottom:0;
  width:100%;
  height:100%;
  .line {
    position:absolute;
    top:auto;
    bottom:calc(50% + 100px);
    left:calc(50% - 30px);
    width:60px;
    height:60px;
    transform:scaleX(-1);
    .btn {
      position:absolute;
      top:0;
      left:0;
      width:60px;
      height:60px;
      pointer-events: none;
    }
    .line-inner {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      overflow:hidden;
    }
  }
  .poifocus {
    position:absolute;
    left:20px;
    top:auto;
    bottom:60px;
    width:calc( 100% - 40px );
    .title {
      font-size:16px;
      margin-bottom:5px;
      padding-right:20px;
    }
    .text {
      div {
        font-size:14px;
      }
    }

    .close {
      position:absolute;
      top:2px;
      right:6px;
      cursor:pointer;
      pointer-events:all;
      svg {
        width:30px;
        height:30px;
        stroke-width:2px;
      }
    }
  }
}
`;
export default PoiFocusStyled;
