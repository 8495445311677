import React, { useContext } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import WindowXsModalStyled from './WindowXsModalStyled';
import Data from 'Data';
import {ReactComponent as Cross} from 'assets/CROSS_ICON_black.svg';

function WindowXsModal({title,subtitle,text,open,onClose}) {
  const { dashboardWidth, dashboardHeight, dashboardLeft, dashboardTop, dashboardBottom, ratio } = useContext(SettingsContext);
  const width=dashboardWidth;
  const height=dashboardHeight;
  const left=dashboardLeft;
  const top=dashboardTop;
  const bottom=dashboardBottom;
  return (
    <WindowXsModalStyled className={(open?' open':'')} css={Data.css} params={{width, height, left, bottom, top, ratio}}>
      <div>
        <h3 className='title'>
          <div className='close' onClick={onClose}><Cross/></div>
          {title}
        </h3>
        <div className='subtitle'>
          {subtitle}
        </div>
        <div className='text'>
          <div>
            {text}
          </div>
        </div>
      </div>
    </WindowXsModalStyled>
  )
}
export default WindowXsModal;
