import React, { useContext, useCallback } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import Lottie from "lottie-react";
import idleBouton from "assets/IdleBouton.json";
import PoiFocusStyled from './PoiFocusStyled';
import Data from 'Data';
import {ReactComponent as ItemLine} from 'assets/item-line.svg';
import {ReactComponent as Cross} from 'assets/CROSS_ICON_black.svg';

function PoiFocus({number,title,subtitle,text}) {
  const { appState, setAppState, screenSize } = useContext(SettingsContext);
  const { poiStep, playing }=appState;
  const handleBack=useCallback(()=>{
    if (!playing) {
      setAppState(state=>{return{...state,poiStep:null}});
    }
  },[setAppState,playing]);

  return (
  <PoiFocusStyled className={(poiStep===number?' selected':'')} css={Data.css} aria-hidden={poiStep!==number}>
    <div className='poifocus'>
      {screenSize!=='xs' && <div className='line'>
        <div className='line-inner'>
          <ItemLine/>
        </div>
        <div className='btn svg-shadow'>
          <Lottie animationData={idleBouton}/>
        </div>
      </div>}
      {screenSize==='xs' && <div className='close' onClick={handleBack}><Cross/></div>}
      <h3 className='title'>
        {title}
      </h3>
      {screenSize !=='xs' && <div className='subtitle'>
        {subtitle}
      </div>}
      <div className='text'>
        <div>
          {text}
        </div>
      </div>
    </div>
    {screenSize==='xs' && <div className='line'>
      <div className='btn svg-shadow'>
        <Lottie animationData={idleBouton}/>
      </div>
    </div>}
  </PoiFocusStyled>
  )
}
export default PoiFocus;
