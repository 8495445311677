import styled from 'styled-components';

const WindowStyled = styled.div`
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:95px;
  opacity:0;
  pointer-events: none;
  transition: opacity 1s;
  &>div {
    position:relative;
    text-align:center;
    width:100%;
    transition: opacity 1s;
    pointer-events:none;
    .title {
      color:${props=>props.css.darkGrey};
      font-size:20px;
      line-height:1;
      margin-top:50px;
      margin-bottom:0;
      pointer-events:none;
      padding: 0 42px;
    }
    .subtitle {
      font-size:16px;
      color:${props=>props.css.darkGrey};
      text-transform:uppercase;
    }
    .info-btn {
      position:absolute;
      right:${props=>38 - props.params.dashboardLeft/props.params.ratio}px;
      top:1px;
      cursor:pointer;
      width:35px;
      height:35px;
      svg {
        width:40px;
        height:35px;
      }
    }
  }
  &.show {
    opacity:1;
    &>div {
      .info-btn {
        pointer-events:all;
      }
    }
  }
`;
export default WindowStyled;
