import parse from 'html-react-parser';
import { Vector3,Box2 } from 'three';
import { getLegalDrinkingAge } from './LegalAge';
import { differenceInYears } from "date-fns";
import _ from 'lodash';
const Utils={
  canDrink:(country, birthdate)=>{
    const today = new Date();
    const age = differenceInYears(today, birthdate);
    const legalAge = getLegalDrinkingAge(country);
    console.log(age,legalAge);
    return legalAge > 0 && age >= legalAge;
  },
  f:(x)=>{
    const a=0.8;
    const b=2.5;
    const c=1.668;
    const d=1.68;
    return Math.max(c,Math.min(d,(d-(c-d)*a/(b-a))+(c-d)*x/(b-a)));
  },
  nbsp:(text)=>{
    let res=text+'';
    res=res.replace(/[ ]+!/gm,"&nbsp;!");
    res=res.replace(/[ ]+\?/gm,"&nbsp;?");
    res=res.replace(/[ ]+:/gm,"&nbsp;:");
    res=res.replace(/[ ]+;/gm,"&nbsp;;");
    return res;
  },
  pc:(s)=>_.upperFirst(_.camelCase(s)),
  hexToRgb:(hex)=>{
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  },
  parseWithLinks:(text,replacements=[])=>{
    if (text) {
      let res=Utils.nbsp(text);
      const options = {
        replace: ({ type, data, name, attribs, children }) => {
          if (
            name === 'iframe'
          ) {
            const {width,height,...others} = attribs;
            const findFor = ['accept','acceptcharset','accesskey','action','allowfullscreen','alt','async','autocomplete','autofocus','autoplay','capture','cellpadding','cellspacing','challenge','charset','checked','cite','classid','classname','colspan','cols','content','contenteditable','contextmenu','controls','controlslist','coords','crossorigin','data','datetime','default','defer','dir','disabled','download','draggable','enctype','form','formaction','formenctype','formmethod','formnovalidate','formtarget','frameborder','headers','height','hidden','high','href','hreflang','htmlfor','httpequiv','icon','id','inputmode','integrity','is','keyparams','keytype','kind','label','lang','list','loop','low','manifest','marginheight','marginwidth','max','maxlength','media','mediagroup','method','min','minlength','multiple','muted','name','novalidate','nonce','open','optimum','pattern','placeholder','poster','preload','profile','radiogroup','readonly','rel','required','reversed','role','rowspan','rows','sandbox','scope','scoped','scrolling','seamless','selected','shape','size','sizes','span','spellcheck','src','srcdoc','srclang','srcset','start','step','style','summary','tabindex','target','title','type','usemap','value','width','wmode','wrap'];
            const replaceWith = ['accept','acceptCharset','accessKey','action','allowFullScreen','alt','async','autoComplete','autoFocus','autoPlay','capture','cellPadding','cellSpacing','challenge','charSet','checked','cite','classID','className','colSpan','cols','content','contentEditable','contextMenu','controls','controlsList','coords','crossOrigin','data','dateTime','default','defer','dir','disabled','download','draggable','encType','form','formAction','formEncType','formMethod','formNoValidate','formTarget','frameBorder','headers','height','hidden','high','href','hrefLang','htmlFor','httpEquiv','icon','id','inputMode','integrity','is','keyParams','keyType','kind','label','lang','list','loop','low','manifest','marginHeight','marginWidth','max','maxLength','media','mediaGroup','method','min','minLength','multiple','muted','name','noValidate','nonce','open','optimum','pattern','placeholder','poster','preload','profile','radioGroup','readOnly','rel','required','reversed','role','rowSpan','rows','sandbox','scope','scoped','scrolling','seamless','selected','shape','size','sizes','span','spellCheck','src','srcDoc','srcLang','srcSet','start','step','style','summary','tabIndex','target','title','type','useMap','value','width','wmode','wrap'];
            const parsedAttrs={};
            Object.keys(others).forEach(attr=>{
              let value=others[attr]
              let modifiedAttr=attr;
              findFor.forEach( (tag, i) => modifiedAttr = modifiedAttr.replace(new RegExp(tag, "g"), replaceWith[i]) );
              parsedAttrs[modifiedAttr]=value;
            })
            const timestamp=Date.now();
            return <iframe title={'embed'+timestamp} {...parsedAttrs}/>;
          }
          for (const item of replacements) {
            if (attribs && attribs.id === item.id) return item.replacement;
          }
        }
      };
      return parse(res, options);
    }
    return '';
  },
  computeScreenSpaceBoundingBox:(mesh, camera)=>{
    const positionAttribute = mesh.geometry.getAttribute('position');
    var point = new Vector3();
    var min = new Vector3(1, 1, 1);
    var max = new Vector3(-1, -1, -1);

    for (var i = 0; i < positionAttribute.count; i++) {
      point.fromBufferAttribute(positionAttribute, i);
      point.applyMatrix4(mesh.matrixWorld);
      var vertexScreenSpace = point.project(camera);
      min.min(vertexScreenSpace);
      max.max(vertexScreenSpace);
    }
    return new Box2(min, max);
  },
  computeScreenSpacePosition:(mesh, camera)=>{
    var point = new Vector3();
    var min = new Vector3(1, 1, 1);
    var max = new Vector3(-1, -1, -1);
    point.copy(mesh.position);
    var vertexScreenSpace = point.project(camera);
    min.min(vertexScreenSpace);
    max.max(vertexScreenSpace);
    return new Box2(min, max);;
  }
}
export default Utils;
