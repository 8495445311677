import React, { useEffect, useRef } from 'react';
import CountriesStyled from './CountriesStyled';
import Data from 'Data';
import { LEGAL_DRINKING_AGES } from 'components/LegalAge';

function Countries({value,onChange}) {
  const select = useRef(null);
  const init = useRef(false);
  useEffect(()=>{
    setTimeout(()=>{
      const elts=select.current.getElementsByClassName('selected');
      if (elts && elts.length>0) {
        const container = select.current.parentNode;
        const node=elts[0];
        const top=node.offsetTop-container.offsetTop-container.clientHeight/2+node.clientHeight/2;
        if(init.current) select.current.parentNode.scrollTo({top,behavior:'smooth'});
        else {
          select.current.parentNode.scrollTo({top,behavior:'instant'});
          init.current=true;
        }
      }
    },100);
  },[value])
  return (
  <CountriesStyled css={Data.css} ref={select}>
    {Object.keys(LEGAL_DRINKING_AGES).map((k)=>{
      const o=LEGAL_DRINKING_AGES[k];
      return <div key={k} onClick={()=>onChange(k)} className={'country'+(value===k?' selected':'')}>
        {o.name}
      </div>;
    })}
  </CountriesStyled>
  )
}
export default Countries;
