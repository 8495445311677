import styled from 'styled-components';

const ExploreStyled = styled.div`
  position:absolute;
  bottom:${props => 38 - props.params.bottom/props.params.ratio}px;
  left:${props => -props.params.left/props.params.ratio + 60}px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#FFF;
  opacity:0;
  pointer-events:none;
  transition: opacity 1s;
  &.visible {
    opacity:1;
    pointer-events:all;
  }
  cursor:pointer;
  .info {
    font-size:16px;
    text-transform:uppercase;
    margin: 0 10px;
  }
  button {
    border:none;
    background:none;
    padding:none;
    cursor:pointer;
    svg {
      position:relative;
      left:-3px;
      height:30px;
      stroke:#FFF;
      stroke-width:3px;
    }
    transform:rotate(90deg);
  }
  &:hover {
    button {
      animation: 1s linear 0s infinite nextExplore;
    }
  }
  .xs &:hover {
    button {
      animation: none;
    }
  }
  .xs & {
    bottom:${props => 0 - props.params.bottom/props.params.ratio}px;
    left:0;
    color:${props=>props.css.darkGrey};
    width:100%;
    flex-direction:column-reverse;
    .info {
      font-size: 12px;
      line-height: 15px;
      position:relative;
      top:5px;
    }
    button {
      svg {
        stroke:${props=>props.css.darkGrey};
        height:30px;
        width:20px;
        stroke-width:3px;
        PADDING:0;
      }
    }
  }
  @keyframes nextExplore {
    0% { opacity:1;transform:translate3d(0,0,0) rotate(90deg); }
    100% { opacity:0;transform:translate3d(0,10px,0) rotate(90deg); }
  }
`;
export default ExploreStyled;
