import styled from 'styled-components';

const CountriesStyled = styled.div`
user-select:none;
  .country {
    position:relative;
    cursor:pointer;
    text-transform:uppercase;
    text-align:left;
    padding:2px 10px;
    line-height:24px;
    font-size:18px;
    &.selected {
      background-color:#fff;
      color:${props=>props.css.darkGrey};
      span {
        line-height:24px;
        position:absolute;
        right:10px;
        top:2px;
        font-size:16px;
        font-weight:bold;
      }
    }
  }
`;
export default CountriesStyled;
