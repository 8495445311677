import styled from 'styled-components';

const SalleStyled = styled.div`
  position: absolute;
  width:${props => props.params.appWidth}px;
  height:${props => props.params.appHeight}px;
  top:0;
  left:0;
  .salle {
    opacity:0;
    &.show {
      opacity:1;
    }
    position: relative;
    width: ${props => props.params.wpWidth}px;
    height: ${props => props.params.wpHeight}px;
    top:${props => props.params.wpTop}px;
    left:${props => props.params.wpLeft}px;
    filter:blur(10px);
    &.started {
      filter:blur(0);
    }
    transform:translate3d(0,0,0);
    transition:transform 1s 0.5s, filter 1s, opacity 1s 1s;
    &.right {
      transition:transform 1s, filter 1s, opacity 1s 1s;
      transform:translate3d(10%,0,0);
    }
    &.hasgoneright.right {
      transition:transform 1s, filter 1s, opacity 1s 1s;
    }
    &.resetright {
      transition:transform 1s, filter 1s, opacity 1s 1s;
      transform:translate3d(0,0,0);
    }
  }
`;
export default SalleStyled;
