import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import Splashscreen from "./Splashscreen";
import Nav from "./Nav";
import Explore from "./Explore";
import Overlay from "./Overlay";
import Start from "./Start";
import Data from "Data";
import UiStyled from './UiStyled'
import Lottie from "lottie-react";
import turnIcon from "assets/360-ICON.json";

function Ui(props) {
  const { t, setAppState, appState, transitionId, screenSize, ratio, initialState, dashboardWidth, dashboardHeight, dashboardLeft, dashboardTop, dashboardBottom } = useContext(SettingsContext);
  const { xpStarted, poiStep,uiStep,step,showTurnHelper }=appState;
  const showTurn=useMemo(()=>uiStep==='discover' && poiStep===null,[uiStep,poiStep]);
  const stepRef=useRef(step);
  const timer=useRef(0);
  const [item,setItem]=useState({});
  const [show,setShow]=useState(false);
  const startTimer=useCallback(()=>{
    if (Data.mode==='retail') {
      console.log('startTimer');
      timer.current=setTimeout(()=>{
        setShow(false);
        setAppState(state=>{
          console.log('reset');
          return {
            ...state,
            ...initialState,
            nav:'home',
            xpStarted:true,
          }
        })
      },Data.idleTimeout);
    }
  },[setAppState,initialState]);
  const clearTimer=useCallback(()=>{
    if (Data.mode==='retail') {
      console.log('remove timer')
      clearTimeout(timer.current);
    }
  },[]);
  useEffect(()=>{
    stepRef.current=step
    startTimer();
    return ()=>clearTimer();
  },[step,startTimer,clearTimer]);
  const handlePointerDown=useCallback(()=>{
      clearTimer();
      startTimer();
  },[startTimer,clearTimer]);
  useEffect(()=>{
    if (transitionId) {
      const transition=Data.stepState[transitionId];
      if (transition) {
        const {itemFrom, itemTo, duration} = transition;
        if (itemFrom!==itemTo) {
          setItem({id:itemFrom,...Data.items[itemFrom]});
          setShow(false);
          const timer=setTimeout(()=>{
            setItem({id:itemTo,...Data.items[itemTo]});
          },1000);
          const timer2=setTimeout(()=>{
            if (stepRef.current>0) setShow(true);
          },(duration-0.5)*1000);
          return ()=>{
            clearTimeout(timer);
            clearTimeout(timer2);
          }
        }
      }
    } else {
      const id=Object.keys(Data.items)[stepRef.current];
      setItem({id,...Data.items[id]});
    }
  },[transitionId]);
  const width=dashboardWidth;
  const height=dashboardHeight;
  const left=dashboardLeft;
  const top=dashboardTop;
  const bottom=dashboardBottom;
  return (
    <>
    <UiStyled
    onPointerDown={handlePointerDown}
    className={screenSize+(step>0 ? ' started' : '')+(Data.mode==='embed' ? ' embed' : '')}
    ratio={ratio}
    params={{width,height,left,top,bottom,ratio}}>
      {xpStarted ? <>
        <div className={'greymask'+(step===0?' visible' : '')} aria-hidden></div>
        <div className={'ui-overlay'+(show && step>0?' show':'')+(showTurn ?' showturn':'')} aria-hidden={!show}>
          <Overlay item={item}/>
        </div>
        <Nav className={step>0?'visible':''}/>
        <Splashscreen className={step===0?'visible':''}/>
        {Data.langAsked && <Explore/>}
        {screenSize==='xs' && <div className={'turn-helper'+(showTurnHelper?' visible' : '')}>
          <div className="anim">
            <Lottie animationData={turnIcon}/>
          </div>
          <div className="info">
            {t('press-and-drag','html')}
          </div>
        </div>}
      </> :
      <Start/>}
    </UiStyled>
    </>
  )
}
export default Ui;
