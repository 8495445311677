/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useCallback, useContext} from "react";
import { useGLTF } from "@react-three/drei";
import Data from "Data";
import { SettingsContext } from 'providers/SettingsProvider';

function Room(props) {
  const { getBlobUrl } = useContext(SettingsContext);
  const { nodes, materials } = useGLTF(getBlobUrl(Data.nodeParams.enviroXs.src));
  const {setObject} = props;
  const onRefChange = useCallback(node => {
    if (node !== null) {
      setObject(node);
    }
  }, [setObject]);
  return (
    <group ref={onRefChange} {...props} dispose={null} position={[0,-2.3,0]}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Ground.geometry}
          material={materials.Ground}
          scale={0.01}
        />
      </group>
      <group position={[0.02, -0.05, -0.01]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Piedestals.geometry}
          material={materials.Piedestals}
          scale={0.01}
        />
      </group>
      <group position={[0.02, 0.25, -0.13]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.DeskLamp01.geometry}
          material={materials.Lamps}
          scale={0.01}
        />
      </group>
      <group position={[0.02, 0.25, -0.13]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.DeskLamp02.geometry}
          material={materials.Lamps}
          scale={0.01}
        />
      </group>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Leather02.geometry}
          material={materials.Leather}
          scale={0.01}
        />
      </group>
      <group position={[0.48, 0.56, -0.75]}>
        <mesh
          geometry={nodes.Table.geometry}
          material={materials.Table}
          scale={0.01}
        />
      </group>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Wall_Cylinder.geometry}
          material={materials.Walls}
          scale={0.01}
        />
      </group>
      <group position={[0.02, 0.25, -0.13]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Lamp_Light.geometry}
          material={materials.Ground_Emissive}
          scale={0.01}
        />
      </group>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Paper03.geometry}
          material={materials.Paper03}
          scale={0.01}
        />
      </group>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Paper02.geometry}
          material={materials.Paper02}
          scale={0.01}
        />
      </group>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Paper01.geometry}
          material={materials.Paper01}
          scale={0.01}
        />
      </group>
      <group
        position={[1.76, 0.62, -1.51]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.38}
      >
        <group scale={0.01}>
          <mesh
            geometry={nodes.WinePlant_1.geometry}
            material={materials.Leaves}
          />
          <mesh
            geometry={nodes.WinePlant_2.geometry}
            material={materials.Trunk}
          />
        </group>
      </group>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Leather01.geometry}
          material={materials.Leather}
          scale={0.01}
        />
      </group>
      <group
        position={[-0.98, 1.02, -0.21]}
        rotation={[0, 0.88, 0]}
        scale={1.91}
      >
        <mesh
          geometry={nodes.Limited_Edition_Unwrapped.geometry}
          material={materials.Leather_Bottle}
          scale={0.01}
        />
      </group>
    </group>
  );
}
export default Room;
