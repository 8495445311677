import React, { useContext, useMemo } from 'react';
import { SettingsContext } from 'providers/SettingsProvider';
import {ReactComponent as Info} from 'assets/info.svg';
import WindowXsStyled from './WindowXsStyled';
import Data from 'Data';

function WindowXs({title,subtitle,text}) {
  const { appState, setAppState, dashboardLeft, ratio } = useContext(SettingsContext);
  const { poiStep,uiStep }=appState;
  const show=useMemo(()=>uiStep==='discover',[uiStep]);
  const poiSelected=useMemo(()=>poiStep!==null,[poiStep]);
  const handleOpen=()=>{
    setAppState(state=>{return{...state,xsModalOpen:true}});
  }
  return (
    <WindowXsStyled className={(show && !poiSelected?' show':'')} params={{ratio,dashboardLeft}} css={Data.css}>
      <div>
        <h3 className='title'>
          {title}
        </h3>
        <div className='subtitle'>
          {subtitle}
        </div>
        <div className='info-btn' onClick={handleOpen}>
          <Info/>
        </div>
      </div>
    </WindowXsStyled>
  )
}
export default WindowXs;
